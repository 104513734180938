/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { CatalogItem, Image, Tag } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface GetItemsRequest {
  category: string;
  brand: string;
  kioskLocation: string;
}

export interface GetItemsResponse {
  item: CatalogItem[];
}

export interface GetItemRequest {
  id: string;
  kioskLocation: string;
}

export interface GetItemResponse {
  item: CatalogItem | undefined;
}

export interface FetchItemsFromMenuApiRequest {
}

export interface FetchItemsFromMenuApiResponse {
}

export interface FetchPromotionsRequest {
}

export interface FetchPromotionsResponse {
}

export interface Promotion {
  id: string;
  title: string;
  target?: string | undefined;
  searchTag: string;
  imagePath: string;
  startDate: Date | undefined;
  expiryDate: Date | undefined;
}

export interface Category {
  id: string;
  name: string;
  image: Image | undefined;
  notActiveImage: Image | undefined;
  basketIcon: Image | undefined;
  homeImage: Image | undefined;
  hidden?: boolean | undefined;
  parentId?: string | undefined;
  tags: Tag[];
}

export interface GetCategoryRequest {
  categoryId: string;
  shouldGetItems?: boolean | undefined;
  kioskLocation: string;
}

export interface GetCategoryResponse {
  category: Category | undefined;
  item: CatalogItem[];
}

export interface GetCategoryTreeRequest {
  brand?: string | undefined;
  parentId?: string | undefined;
}

export interface GetCategoryTreeResponse {
  category: Category[];
  item: CatalogItem[];
}

export interface GetFridgeProductPromoRequest {
  kioskLocation: string;
}

export interface GetFridgeProductPromoResponse {
  item: CatalogItem | undefined;
}

function createBaseGetItemsRequest(): GetItemsRequest {
  return { category: "", brand: "", kioskLocation: "" };
}

export const GetItemsRequest = {
  encode(message: GetItemsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.category !== "") {
      writer.uint32(10).string(message.category);
    }
    if (message.brand !== "") {
      writer.uint32(18).string(message.brand);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(26).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetItemsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetItemsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetItemsRequest {
    return {
      category: isSet(object.category) ? String(object.category) : "",
      brand: isSet(object.brand) ? String(object.brand) : "",
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: GetItemsRequest): unknown {
    const obj: any = {};
    if (message.category !== "") {
      obj.category = message.category;
    }
    if (message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetItemsRequest>, I>>(base?: I): GetItemsRequest {
    return GetItemsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetItemsRequest>, I>>(object: I): GetItemsRequest {
    const message = createBaseGetItemsRequest();
    message.category = object.category ?? "";
    message.brand = object.brand ?? "";
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetItemsResponse(): GetItemsResponse {
  return { item: [] };
}

export const GetItemsResponse = {
  encode(message: GetItemsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.item) {
      CatalogItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetItemsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetItemsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.item.push(CatalogItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetItemsResponse {
    return { item: Array.isArray(object?.item) ? object.item.map((e: any) => CatalogItem.fromJSON(e)) : [] };
  },

  toJSON(message: GetItemsResponse): unknown {
    const obj: any = {};
    if (message.item?.length) {
      obj.item = message.item.map((e) => CatalogItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetItemsResponse>, I>>(base?: I): GetItemsResponse {
    return GetItemsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetItemsResponse>, I>>(object: I): GetItemsResponse {
    const message = createBaseGetItemsResponse();
    message.item = object.item?.map((e) => CatalogItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetItemRequest(): GetItemRequest {
  return { id: "", kioskLocation: "" };
}

export const GetItemRequest = {
  encode(message: GetItemRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(18).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetItemRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetItemRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetItemRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: GetItemRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetItemRequest>, I>>(base?: I): GetItemRequest {
    return GetItemRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetItemRequest>, I>>(object: I): GetItemRequest {
    const message = createBaseGetItemRequest();
    message.id = object.id ?? "";
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetItemResponse(): GetItemResponse {
  return { item: undefined };
}

export const GetItemResponse = {
  encode(message: GetItemResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.item !== undefined) {
      CatalogItem.encode(message.item, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetItemResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetItemResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.item = CatalogItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetItemResponse {
    return { item: isSet(object.item) ? CatalogItem.fromJSON(object.item) : undefined };
  },

  toJSON(message: GetItemResponse): unknown {
    const obj: any = {};
    if (message.item !== undefined) {
      obj.item = CatalogItem.toJSON(message.item);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetItemResponse>, I>>(base?: I): GetItemResponse {
    return GetItemResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetItemResponse>, I>>(object: I): GetItemResponse {
    const message = createBaseGetItemResponse();
    message.item = (object.item !== undefined && object.item !== null)
      ? CatalogItem.fromPartial(object.item)
      : undefined;
    return message;
  },
};

function createBaseFetchItemsFromMenuApiRequest(): FetchItemsFromMenuApiRequest {
  return {};
}

export const FetchItemsFromMenuApiRequest = {
  encode(_: FetchItemsFromMenuApiRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchItemsFromMenuApiRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchItemsFromMenuApiRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchItemsFromMenuApiRequest {
    return {};
  },

  toJSON(_: FetchItemsFromMenuApiRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchItemsFromMenuApiRequest>, I>>(base?: I): FetchItemsFromMenuApiRequest {
    return FetchItemsFromMenuApiRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FetchItemsFromMenuApiRequest>, I>>(_: I): FetchItemsFromMenuApiRequest {
    const message = createBaseFetchItemsFromMenuApiRequest();
    return message;
  },
};

function createBaseFetchItemsFromMenuApiResponse(): FetchItemsFromMenuApiResponse {
  return {};
}

export const FetchItemsFromMenuApiResponse = {
  encode(_: FetchItemsFromMenuApiResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchItemsFromMenuApiResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchItemsFromMenuApiResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchItemsFromMenuApiResponse {
    return {};
  },

  toJSON(_: FetchItemsFromMenuApiResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchItemsFromMenuApiResponse>, I>>(base?: I): FetchItemsFromMenuApiResponse {
    return FetchItemsFromMenuApiResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FetchItemsFromMenuApiResponse>, I>>(_: I): FetchItemsFromMenuApiResponse {
    const message = createBaseFetchItemsFromMenuApiResponse();
    return message;
  },
};

function createBaseFetchPromotionsRequest(): FetchPromotionsRequest {
  return {};
}

export const FetchPromotionsRequest = {
  encode(_: FetchPromotionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchPromotionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchPromotionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchPromotionsRequest {
    return {};
  },

  toJSON(_: FetchPromotionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchPromotionsRequest>, I>>(base?: I): FetchPromotionsRequest {
    return FetchPromotionsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FetchPromotionsRequest>, I>>(_: I): FetchPromotionsRequest {
    const message = createBaseFetchPromotionsRequest();
    return message;
  },
};

function createBaseFetchPromotionsResponse(): FetchPromotionsResponse {
  return {};
}

export const FetchPromotionsResponse = {
  encode(_: FetchPromotionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchPromotionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchPromotionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchPromotionsResponse {
    return {};
  },

  toJSON(_: FetchPromotionsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchPromotionsResponse>, I>>(base?: I): FetchPromotionsResponse {
    return FetchPromotionsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FetchPromotionsResponse>, I>>(_: I): FetchPromotionsResponse {
    const message = createBaseFetchPromotionsResponse();
    return message;
  },
};

function createBasePromotion(): Promotion {
  return {
    id: "",
    title: "",
    target: undefined,
    searchTag: "",
    imagePath: "",
    startDate: undefined,
    expiryDate: undefined,
  };
}

export const Promotion = {
  encode(message: Promotion, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.target !== undefined) {
      writer.uint32(26).string(message.target);
    }
    if (message.searchTag !== "") {
      writer.uint32(34).string(message.searchTag);
    }
    if (message.imagePath !== "") {
      writer.uint32(42).string(message.imagePath);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.expiryDate !== undefined) {
      Timestamp.encode(toTimestamp(message.expiryDate), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Promotion {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePromotion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.target = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.searchTag = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.imagePath = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.expiryDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Promotion {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      target: isSet(object.target) ? String(object.target) : undefined,
      searchTag: isSet(object.searchTag) ? String(object.searchTag) : "",
      imagePath: isSet(object.imagePath) ? String(object.imagePath) : "",
      startDate: isSet(object.startDate) ? fromJsonTimestamp(object.startDate) : undefined,
      expiryDate: isSet(object.expiryDate) ? fromJsonTimestamp(object.expiryDate) : undefined,
    };
  },

  toJSON(message: Promotion): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.target !== undefined) {
      obj.target = message.target;
    }
    if (message.searchTag !== "") {
      obj.searchTag = message.searchTag;
    }
    if (message.imagePath !== "") {
      obj.imagePath = message.imagePath;
    }
    if (message.startDate !== undefined) {
      obj.startDate = message.startDate.toISOString();
    }
    if (message.expiryDate !== undefined) {
      obj.expiryDate = message.expiryDate.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Promotion>, I>>(base?: I): Promotion {
    return Promotion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Promotion>, I>>(object: I): Promotion {
    const message = createBasePromotion();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.target = object.target ?? undefined;
    message.searchTag = object.searchTag ?? "";
    message.imagePath = object.imagePath ?? "";
    message.startDate = object.startDate ?? undefined;
    message.expiryDate = object.expiryDate ?? undefined;
    return message;
  },
};

function createBaseCategory(): Category {
  return {
    id: "",
    name: "",
    image: undefined,
    notActiveImage: undefined,
    basketIcon: undefined,
    homeImage: undefined,
    hidden: undefined,
    parentId: undefined,
    tags: [],
  };
}

export const Category = {
  encode(message: Category, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.image !== undefined) {
      Image.encode(message.image, writer.uint32(26).fork()).ldelim();
    }
    if (message.notActiveImage !== undefined) {
      Image.encode(message.notActiveImage, writer.uint32(34).fork()).ldelim();
    }
    if (message.basketIcon !== undefined) {
      Image.encode(message.basketIcon, writer.uint32(42).fork()).ldelim();
    }
    if (message.homeImage !== undefined) {
      Image.encode(message.homeImage, writer.uint32(50).fork()).ldelim();
    }
    if (message.hidden !== undefined) {
      writer.uint32(56).bool(message.hidden);
    }
    if (message.parentId !== undefined) {
      writer.uint32(66).string(message.parentId);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Category {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCategory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.image = Image.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.notActiveImage = Image.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.basketIcon = Image.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.homeImage = Image.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Category {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      notActiveImage: isSet(object.notActiveImage) ? Image.fromJSON(object.notActiveImage) : undefined,
      basketIcon: isSet(object.basketIcon) ? Image.fromJSON(object.basketIcon) : undefined,
      homeImage: isSet(object.homeImage) ? Image.fromJSON(object.homeImage) : undefined,
      hidden: isSet(object.hidden) ? Boolean(object.hidden) : undefined,
      parentId: isSet(object.parentId) ? String(object.parentId) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [],
    };
  },

  toJSON(message: Category): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.notActiveImage !== undefined) {
      obj.notActiveImage = Image.toJSON(message.notActiveImage);
    }
    if (message.basketIcon !== undefined) {
      obj.basketIcon = Image.toJSON(message.basketIcon);
    }
    if (message.homeImage !== undefined) {
      obj.homeImage = Image.toJSON(message.homeImage);
    }
    if (message.hidden !== undefined) {
      obj.hidden = message.hidden;
    }
    if (message.parentId !== undefined) {
      obj.parentId = message.parentId;
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Category>, I>>(base?: I): Category {
    return Category.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Category>, I>>(object: I): Category {
    const message = createBaseCategory();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.notActiveImage = (object.notActiveImage !== undefined && object.notActiveImage !== null)
      ? Image.fromPartial(object.notActiveImage)
      : undefined;
    message.basketIcon = (object.basketIcon !== undefined && object.basketIcon !== null)
      ? Image.fromPartial(object.basketIcon)
      : undefined;
    message.homeImage = (object.homeImage !== undefined && object.homeImage !== null)
      ? Image.fromPartial(object.homeImage)
      : undefined;
    message.hidden = object.hidden ?? undefined;
    message.parentId = object.parentId ?? undefined;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCategoryRequest(): GetCategoryRequest {
  return { categoryId: "", shouldGetItems: undefined, kioskLocation: "" };
}

export const GetCategoryRequest = {
  encode(message: GetCategoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.categoryId !== "") {
      writer.uint32(10).string(message.categoryId);
    }
    if (message.shouldGetItems !== undefined) {
      writer.uint32(16).bool(message.shouldGetItems);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(26).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.categoryId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.shouldGetItems = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoryRequest {
    return {
      categoryId: isSet(object.categoryId) ? String(object.categoryId) : "",
      shouldGetItems: isSet(object.shouldGetItems) ? Boolean(object.shouldGetItems) : undefined,
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: GetCategoryRequest): unknown {
    const obj: any = {};
    if (message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    if (message.shouldGetItems !== undefined) {
      obj.shouldGetItems = message.shouldGetItems;
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoryRequest>, I>>(base?: I): GetCategoryRequest {
    return GetCategoryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetCategoryRequest>, I>>(object: I): GetCategoryRequest {
    const message = createBaseGetCategoryRequest();
    message.categoryId = object.categoryId ?? "";
    message.shouldGetItems = object.shouldGetItems ?? undefined;
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetCategoryResponse(): GetCategoryResponse {
  return { category: undefined, item: [] };
}

export const GetCategoryResponse = {
  encode(message: GetCategoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.category !== undefined) {
      Category.encode(message.category, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.item) {
      CatalogItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category = Category.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.item.push(CatalogItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoryResponse {
    return {
      category: isSet(object.category) ? Category.fromJSON(object.category) : undefined,
      item: Array.isArray(object?.item) ? object.item.map((e: any) => CatalogItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCategoryResponse): unknown {
    const obj: any = {};
    if (message.category !== undefined) {
      obj.category = Category.toJSON(message.category);
    }
    if (message.item?.length) {
      obj.item = message.item.map((e) => CatalogItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoryResponse>, I>>(base?: I): GetCategoryResponse {
    return GetCategoryResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetCategoryResponse>, I>>(object: I): GetCategoryResponse {
    const message = createBaseGetCategoryResponse();
    message.category = (object.category !== undefined && object.category !== null)
      ? Category.fromPartial(object.category)
      : undefined;
    message.item = object.item?.map((e) => CatalogItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCategoryTreeRequest(): GetCategoryTreeRequest {
  return { brand: undefined, parentId: undefined };
}

export const GetCategoryTreeRequest = {
  encode(message: GetCategoryTreeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.brand !== undefined) {
      writer.uint32(10).string(message.brand);
    }
    if (message.parentId !== undefined) {
      writer.uint32(18).string(message.parentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoryTreeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoryTreeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.brand = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoryTreeRequest {
    return {
      brand: isSet(object.brand) ? String(object.brand) : undefined,
      parentId: isSet(object.parentId) ? String(object.parentId) : undefined,
    };
  },

  toJSON(message: GetCategoryTreeRequest): unknown {
    const obj: any = {};
    if (message.brand !== undefined) {
      obj.brand = message.brand;
    }
    if (message.parentId !== undefined) {
      obj.parentId = message.parentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoryTreeRequest>, I>>(base?: I): GetCategoryTreeRequest {
    return GetCategoryTreeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetCategoryTreeRequest>, I>>(object: I): GetCategoryTreeRequest {
    const message = createBaseGetCategoryTreeRequest();
    message.brand = object.brand ?? undefined;
    message.parentId = object.parentId ?? undefined;
    return message;
  },
};

function createBaseGetCategoryTreeResponse(): GetCategoryTreeResponse {
  return { category: [], item: [] };
}

export const GetCategoryTreeResponse = {
  encode(message: GetCategoryTreeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.category) {
      Category.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.item) {
      CatalogItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCategoryTreeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCategoryTreeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.category.push(Category.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.item.push(CatalogItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCategoryTreeResponse {
    return {
      category: Array.isArray(object?.category) ? object.category.map((e: any) => Category.fromJSON(e)) : [],
      item: Array.isArray(object?.item) ? object.item.map((e: any) => CatalogItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCategoryTreeResponse): unknown {
    const obj: any = {};
    if (message.category?.length) {
      obj.category = message.category.map((e) => Category.toJSON(e));
    }
    if (message.item?.length) {
      obj.item = message.item.map((e) => CatalogItem.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCategoryTreeResponse>, I>>(base?: I): GetCategoryTreeResponse {
    return GetCategoryTreeResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetCategoryTreeResponse>, I>>(object: I): GetCategoryTreeResponse {
    const message = createBaseGetCategoryTreeResponse();
    message.category = object.category?.map((e) => Category.fromPartial(e)) || [];
    message.item = object.item?.map((e) => CatalogItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFridgeProductPromoRequest(): GetFridgeProductPromoRequest {
  return { kioskLocation: "" };
}

export const GetFridgeProductPromoRequest = {
  encode(message: GetFridgeProductPromoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFridgeProductPromoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFridgeProductPromoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFridgeProductPromoRequest {
    return { kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "" };
  },

  toJSON(message: GetFridgeProductPromoRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFridgeProductPromoRequest>, I>>(base?: I): GetFridgeProductPromoRequest {
    return GetFridgeProductPromoRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetFridgeProductPromoRequest>, I>>(object: I): GetFridgeProductPromoRequest {
    const message = createBaseGetFridgeProductPromoRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetFridgeProductPromoResponse(): GetFridgeProductPromoResponse {
  return { item: undefined };
}

export const GetFridgeProductPromoResponse = {
  encode(message: GetFridgeProductPromoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.item !== undefined) {
      CatalogItem.encode(message.item, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFridgeProductPromoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFridgeProductPromoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.item = CatalogItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFridgeProductPromoResponse {
    return { item: isSet(object.item) ? CatalogItem.fromJSON(object.item) : undefined };
  },

  toJSON(message: GetFridgeProductPromoResponse): unknown {
    const obj: any = {};
    if (message.item !== undefined) {
      obj.item = CatalogItem.toJSON(message.item);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFridgeProductPromoResponse>, I>>(base?: I): GetFridgeProductPromoResponse {
    return GetFridgeProductPromoResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetFridgeProductPromoResponse>, I>>(
    object: I,
  ): GetFridgeProductPromoResponse {
    const message = createBaseGetFridgeProductPromoResponse();
    message.item = (object.item !== undefined && object.item !== null)
      ? CatalogItem.fromPartial(object.item)
      : undefined;
    return message;
  },
};

export interface Catalog {
  GetItem(
    request: DeepPartial<GetItemRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetItemResponse>;
  GetItems(
    request: DeepPartial<GetItemsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetItemsResponse>;
  FetchItemsFromMenuApi(
    request: DeepPartial<FetchItemsFromMenuApiRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FetchItemsFromMenuApiResponse>;
  FetchPromotions(
    request: DeepPartial<FetchPromotionsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FetchPromotionsResponse>;
  GetCategoryTree(
    request: DeepPartial<GetCategoryTreeRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetCategoryTreeResponse>;
  GetCategory(
    request: DeepPartial<GetCategoryRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetCategoryResponse>;
  GetFridgeProductPromo(
    request: DeepPartial<GetFridgeProductPromoRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetFridgeProductPromoResponse>;
}

export class CatalogClientImpl implements Catalog {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetItem = this.GetItem.bind(this);
    this.GetItems = this.GetItems.bind(this);
    this.FetchItemsFromMenuApi = this.FetchItemsFromMenuApi.bind(this);
    this.FetchPromotions = this.FetchPromotions.bind(this);
    this.GetCategoryTree = this.GetCategoryTree.bind(this);
    this.GetCategory = this.GetCategory.bind(this);
    this.GetFridgeProductPromo = this.GetFridgeProductPromo.bind(this);
  }

  GetItem(
    request: DeepPartial<GetItemRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetItemResponse> {
    return this.rpc.unary(CatalogGetItemDesc, GetItemRequest.fromPartial(request), metadata, abortSignal);
  }

  GetItems(
    request: DeepPartial<GetItemsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetItemsResponse> {
    return this.rpc.unary(CatalogGetItemsDesc, GetItemsRequest.fromPartial(request), metadata, abortSignal);
  }

  FetchItemsFromMenuApi(
    request: DeepPartial<FetchItemsFromMenuApiRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FetchItemsFromMenuApiResponse> {
    return this.rpc.unary(
      CatalogFetchItemsFromMenuApiDesc,
      FetchItemsFromMenuApiRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  FetchPromotions(
    request: DeepPartial<FetchPromotionsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FetchPromotionsResponse> {
    return this.rpc.unary(
      CatalogFetchPromotionsDesc,
      FetchPromotionsRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  GetCategoryTree(
    request: DeepPartial<GetCategoryTreeRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetCategoryTreeResponse> {
    return this.rpc.unary(
      CatalogGetCategoryTreeDesc,
      GetCategoryTreeRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  GetCategory(
    request: DeepPartial<GetCategoryRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetCategoryResponse> {
    return this.rpc.unary(CatalogGetCategoryDesc, GetCategoryRequest.fromPartial(request), metadata, abortSignal);
  }

  GetFridgeProductPromo(
    request: DeepPartial<GetFridgeProductPromoRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetFridgeProductPromoResponse> {
    return this.rpc.unary(
      CatalogGetFridgeProductPromoDesc,
      GetFridgeProductPromoRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const CatalogDesc = { serviceName: "digitalbutler.v1.Catalog" };

export const CatalogGetItemDesc: UnaryMethodDefinitionish = {
  methodName: "GetItem",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetItemRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetItemResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogGetItemsDesc: UnaryMethodDefinitionish = {
  methodName: "GetItems",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetItemsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetItemsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogFetchItemsFromMenuApiDesc: UnaryMethodDefinitionish = {
  methodName: "FetchItemsFromMenuApi",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchItemsFromMenuApiRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchItemsFromMenuApiResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogFetchPromotionsDesc: UnaryMethodDefinitionish = {
  methodName: "FetchPromotions",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchPromotionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchPromotionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogGetCategoryTreeDesc: UnaryMethodDefinitionish = {
  methodName: "GetCategoryTree",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCategoryTreeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCategoryTreeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogGetCategoryDesc: UnaryMethodDefinitionish = {
  methodName: "GetCategory",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCategoryRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCategoryResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CatalogGetFridgeProductPromoDesc: UnaryMethodDefinitionish = {
  methodName: "GetFridgeProductPromo",
  service: CatalogDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFridgeProductPromoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFridgeProductPromoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
