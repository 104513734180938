/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { OrderStatus, orderStatusFromJSON, orderStatusToJSON } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface SetOrderStatusRequest {
  kioskLocationId: string;
  orderId: string;
  status: OrderStatus;
}

export interface FilteredOrdersRequest {
  kioskLocationId: string;
  onlyCurrentDay: boolean;
  orderStatuses: OrderStatus[];
}

export interface ItemDetail {
  name: string;
  description: string;
  quantity: number;
  priceSubtotal: number;
}

export interface OrderDetail {
  orderId: string;
  items: ItemDetail[];
  totalPrice: number;
  status: OrderStatus;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  friendlyId: number;
  kioskLocationId: string;
  customerName?: string | undefined;
  phoneNumber?: string | undefined;
  email?: string | undefined;
}

export interface FilteredOrdersResponse {
  orders: OrderDetail[];
}

function createBaseSetOrderStatusRequest(): SetOrderStatusRequest {
  return { kioskLocationId: "", orderId: "", status: 0 };
}

export const SetOrderStatusRequest = {
  encode(message: SetOrderStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocationId !== "") {
      writer.uint32(10).string(message.kioskLocationId);
    }
    if (message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetOrderStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetOrderStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetOrderStatusRequest {
    return {
      kioskLocationId: isSet(object.kioskLocationId) ? String(object.kioskLocationId) : "",
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      status: isSet(object.status) ? orderStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: SetOrderStatusRequest): unknown {
    const obj: any = {};
    if (message.kioskLocationId !== "") {
      obj.kioskLocationId = message.kioskLocationId;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.status !== 0) {
      obj.status = orderStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetOrderStatusRequest>, I>>(base?: I): SetOrderStatusRequest {
    return SetOrderStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetOrderStatusRequest>, I>>(object: I): SetOrderStatusRequest {
    const message = createBaseSetOrderStatusRequest();
    message.kioskLocationId = object.kioskLocationId ?? "";
    message.orderId = object.orderId ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseFilteredOrdersRequest(): FilteredOrdersRequest {
  return { kioskLocationId: "", onlyCurrentDay: false, orderStatuses: [] };
}

export const FilteredOrdersRequest = {
  encode(message: FilteredOrdersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocationId !== "") {
      writer.uint32(10).string(message.kioskLocationId);
    }
    if (message.onlyCurrentDay === true) {
      writer.uint32(16).bool(message.onlyCurrentDay);
    }
    writer.uint32(26).fork();
    for (const v of message.orderStatuses) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilteredOrdersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilteredOrdersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocationId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyCurrentDay = reader.bool();
          continue;
        case 3:
          if (tag === 24) {
            message.orderStatuses.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.orderStatuses.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilteredOrdersRequest {
    return {
      kioskLocationId: isSet(object.kioskLocationId) ? String(object.kioskLocationId) : "",
      onlyCurrentDay: isSet(object.onlyCurrentDay) ? Boolean(object.onlyCurrentDay) : false,
      orderStatuses: Array.isArray(object?.orderStatuses)
        ? object.orderStatuses.map((e: any) => orderStatusFromJSON(e))
        : [],
    };
  },

  toJSON(message: FilteredOrdersRequest): unknown {
    const obj: any = {};
    if (message.kioskLocationId !== "") {
      obj.kioskLocationId = message.kioskLocationId;
    }
    if (message.onlyCurrentDay === true) {
      obj.onlyCurrentDay = message.onlyCurrentDay;
    }
    if (message.orderStatuses?.length) {
      obj.orderStatuses = message.orderStatuses.map((e) => orderStatusToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilteredOrdersRequest>, I>>(base?: I): FilteredOrdersRequest {
    return FilteredOrdersRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FilteredOrdersRequest>, I>>(object: I): FilteredOrdersRequest {
    const message = createBaseFilteredOrdersRequest();
    message.kioskLocationId = object.kioskLocationId ?? "";
    message.onlyCurrentDay = object.onlyCurrentDay ?? false;
    message.orderStatuses = object.orderStatuses?.map((e) => e) || [];
    return message;
  },
};

function createBaseItemDetail(): ItemDetail {
  return { name: "", description: "", quantity: 0, priceSubtotal: 0 };
}

export const ItemDetail = {
  encode(message: ItemDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.quantity !== 0) {
      writer.uint32(24).int32(message.quantity);
    }
    if (message.priceSubtotal !== 0) {
      writer.uint32(32).int32(message.priceSubtotal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ItemDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseItemDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.quantity = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.priceSubtotal = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ItemDetail {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      quantity: isSet(object.quantity) ? Number(object.quantity) : 0,
      priceSubtotal: isSet(object.priceSubtotal) ? Number(object.priceSubtotal) : 0,
    };
  },

  toJSON(message: ItemDetail): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    if (message.priceSubtotal !== 0) {
      obj.priceSubtotal = Math.round(message.priceSubtotal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ItemDetail>, I>>(base?: I): ItemDetail {
    return ItemDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ItemDetail>, I>>(object: I): ItemDetail {
    const message = createBaseItemDetail();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.quantity = object.quantity ?? 0;
    message.priceSubtotal = object.priceSubtotal ?? 0;
    return message;
  },
};

function createBaseOrderDetail(): OrderDetail {
  return {
    orderId: "",
    items: [],
    totalPrice: 0,
    status: 0,
    createdAt: undefined,
    updatedAt: undefined,
    friendlyId: 0,
    kioskLocationId: "",
    customerName: undefined,
    phoneNumber: undefined,
    email: undefined,
  };
}

export const OrderDetail = {
  encode(message: OrderDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    for (const v of message.items) {
      ItemDetail.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.totalPrice !== 0) {
      writer.uint32(24).int32(message.totalPrice);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.friendlyId !== 0) {
      writer.uint32(64).int32(message.friendlyId);
    }
    if (message.kioskLocationId !== "") {
      writer.uint32(98).string(message.kioskLocationId);
    }
    if (message.customerName !== undefined) {
      writer.uint32(146).string(message.customerName);
    }
    if (message.phoneNumber !== undefined) {
      writer.uint32(154).string(message.phoneNumber);
    }
    if (message.email !== undefined) {
      writer.uint32(162).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items.push(ItemDetail.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalPrice = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.friendlyId = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.kioskLocationId = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.customerName = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderDetail {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      items: Array.isArray(object?.items) ? object.items.map((e: any) => ItemDetail.fromJSON(e)) : [],
      totalPrice: isSet(object.totalPrice) ? Number(object.totalPrice) : 0,
      status: isSet(object.status) ? orderStatusFromJSON(object.status) : 0,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      friendlyId: isSet(object.friendlyId) ? Number(object.friendlyId) : 0,
      kioskLocationId: isSet(object.kioskLocationId) ? String(object.kioskLocationId) : "",
      customerName: isSet(object.customerName) ? String(object.customerName) : undefined,
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
    };
  },

  toJSON(message: OrderDetail): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => ItemDetail.toJSON(e));
    }
    if (message.totalPrice !== 0) {
      obj.totalPrice = Math.round(message.totalPrice);
    }
    if (message.status !== 0) {
      obj.status = orderStatusToJSON(message.status);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.friendlyId !== 0) {
      obj.friendlyId = Math.round(message.friendlyId);
    }
    if (message.kioskLocationId !== "") {
      obj.kioskLocationId = message.kioskLocationId;
    }
    if (message.customerName !== undefined) {
      obj.customerName = message.customerName;
    }
    if (message.phoneNumber !== undefined) {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderDetail>, I>>(base?: I): OrderDetail {
    return OrderDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderDetail>, I>>(object: I): OrderDetail {
    const message = createBaseOrderDetail();
    message.orderId = object.orderId ?? "";
    message.items = object.items?.map((e) => ItemDetail.fromPartial(e)) || [];
    message.totalPrice = object.totalPrice ?? 0;
    message.status = object.status ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.friendlyId = object.friendlyId ?? 0;
    message.kioskLocationId = object.kioskLocationId ?? "";
    message.customerName = object.customerName ?? undefined;
    message.phoneNumber = object.phoneNumber ?? undefined;
    message.email = object.email ?? undefined;
    return message;
  },
};

function createBaseFilteredOrdersResponse(): FilteredOrdersResponse {
  return { orders: [] };
}

export const FilteredOrdersResponse = {
  encode(message: FilteredOrdersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.orders) {
      OrderDetail.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilteredOrdersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilteredOrdersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orders.push(OrderDetail.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilteredOrdersResponse {
    return { orders: Array.isArray(object?.orders) ? object.orders.map((e: any) => OrderDetail.fromJSON(e)) : [] };
  },

  toJSON(message: FilteredOrdersResponse): unknown {
    const obj: any = {};
    if (message.orders?.length) {
      obj.orders = message.orders.map((e) => OrderDetail.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilteredOrdersResponse>, I>>(base?: I): FilteredOrdersResponse {
    return FilteredOrdersResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FilteredOrdersResponse>, I>>(object: I): FilteredOrdersResponse {
    const message = createBaseFilteredOrdersResponse();
    message.orders = object.orders?.map((e) => OrderDetail.fromPartial(e)) || [];
    return message;
  },
};

export interface OrderOrchestration {
  GetOrdersFilteredByStatusAndCurrentDate(
    request: DeepPartial<FilteredOrdersRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FilteredOrdersResponse>;
  SetOrderStatus(
    request: DeepPartial<SetOrderStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<OrderDetail>;
}

export class OrderOrchestrationClientImpl implements OrderOrchestration {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetOrdersFilteredByStatusAndCurrentDate = this.GetOrdersFilteredByStatusAndCurrentDate.bind(this);
    this.SetOrderStatus = this.SetOrderStatus.bind(this);
  }

  GetOrdersFilteredByStatusAndCurrentDate(
    request: DeepPartial<FilteredOrdersRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<FilteredOrdersResponse> {
    return this.rpc.unary(
      OrderOrchestrationGetOrdersFilteredByStatusAndCurrentDateDesc,
      FilteredOrdersRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  SetOrderStatus(
    request: DeepPartial<SetOrderStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<OrderDetail> {
    return this.rpc.unary(
      OrderOrchestrationSetOrderStatusDesc,
      SetOrderStatusRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const OrderOrchestrationDesc = { serviceName: "digitalbutler.v1.OrderOrchestration" };

export const OrderOrchestrationGetOrdersFilteredByStatusAndCurrentDateDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrdersFilteredByStatusAndCurrentDate",
  service: OrderOrchestrationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FilteredOrdersRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FilteredOrdersResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderOrchestrationSetOrderStatusDesc: UnaryMethodDefinitionish = {
  methodName: "SetOrderStatus",
  service: OrderOrchestrationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetOrderStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = OrderDetail.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
