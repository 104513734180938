/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { BasicOrderItem, BasketResponse } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface SyncOrderRequest {
  orderId?: string | undefined;
  items: BasicOrderItem[];
  kioskLocation: string;
}

export interface FinalizeOrderRequest {
  orderId: string;
}

function createBaseSyncOrderRequest(): SyncOrderRequest {
  return { orderId: undefined, items: [], kioskLocation: "" };
}

export const SyncOrderRequest = {
  encode(message: SyncOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== undefined) {
      writer.uint32(10).string(message.orderId);
    }
    for (const v of message.items) {
      BasicOrderItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.kioskLocation !== "") {
      writer.uint32(26).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SyncOrderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSyncOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items.push(BasicOrderItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SyncOrderRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => BasicOrderItem.fromJSON(e)) : [],
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: SyncOrderRequest): unknown {
    const obj: any = {};
    if (message.orderId !== undefined) {
      obj.orderId = message.orderId;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => BasicOrderItem.toJSON(e));
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SyncOrderRequest>, I>>(base?: I): SyncOrderRequest {
    return SyncOrderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SyncOrderRequest>, I>>(object: I): SyncOrderRequest {
    const message = createBaseSyncOrderRequest();
    message.orderId = object.orderId ?? undefined;
    message.items = object.items?.map((e) => BasicOrderItem.fromPartial(e)) || [];
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseFinalizeOrderRequest(): FinalizeOrderRequest {
  return { orderId: "" };
}

export const FinalizeOrderRequest = {
  encode(message: FinalizeOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FinalizeOrderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFinalizeOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FinalizeOrderRequest {
    return { orderId: isSet(object.orderId) ? String(object.orderId) : "" };
  },

  toJSON(message: FinalizeOrderRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FinalizeOrderRequest>, I>>(base?: I): FinalizeOrderRequest {
    return FinalizeOrderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FinalizeOrderRequest>, I>>(object: I): FinalizeOrderRequest {
    const message = createBaseFinalizeOrderRequest();
    message.orderId = object.orderId ?? "";
    return message;
  },
};

export interface Kiosk {
  FinalizeOrder(
    request: DeepPartial<FinalizeOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse>;
  SyncOrder(
    request: DeepPartial<SyncOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse>;
}

export class KioskClientImpl implements Kiosk {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.FinalizeOrder = this.FinalizeOrder.bind(this);
    this.SyncOrder = this.SyncOrder.bind(this);
  }

  FinalizeOrder(
    request: DeepPartial<FinalizeOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse> {
    return this.rpc.unary(KioskFinalizeOrderDesc, FinalizeOrderRequest.fromPartial(request), metadata, abortSignal);
  }

  SyncOrder(
    request: DeepPartial<SyncOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse> {
    return this.rpc.unary(KioskSyncOrderDesc, SyncOrderRequest.fromPartial(request), metadata, abortSignal);
  }
}

export const KioskDesc = { serviceName: "digitalbutler.v1.Kiosk" };

export const KioskFinalizeOrderDesc: UnaryMethodDefinitionish = {
  methodName: "FinalizeOrder",
  service: KioskDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FinalizeOrderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BasketResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const KioskSyncOrderDesc: UnaryMethodDefinitionish = {
  methodName: "SyncOrder",
  service: KioskDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SyncOrderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BasketResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
