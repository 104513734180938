/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Struct } from "../../google/protobuf/struct";
import { Currency, currencyFromJSON, currencyToJSON } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface HandlePaymentRequest {
  amount: number;
  currency: Currency;
  kioskLocation: string;
  paymentProviderMetadata: { [key: string]: any } | undefined;
  orderId: string;
}

export interface HandlePaymentResponse {
  isSuccessful: boolean;
  id: string;
}

/** Stripe */
export interface StripeGetConnectionTokenRequest {
}

export interface StripeGetConnectionTokenResponse {
  secret: string;
}

export interface StripeCreatePaymentIntentRequest {
  amount: number;
  currency: Currency;
}

export interface StripeCreatePaymentIntentResponse {
  clientSecret: string;
}

export interface StripeCreateAndProcessPaymentIntentRequest {
  amount: number;
  currency: Currency;
  stripeLocationId: string;
  stripeTerminalId: string;
}

export interface StripeCreateAndProcessPaymentIntentResponse {
  paymentIntentId: string;
}

export interface StripeProcessPaymentIntentRequest {
  stripeLocationId: string;
  paymentIntentId: string;
}

export interface StripeProcessPaymentIntentResponse {
  paymentIntentId: string;
}

export interface StripeSimulatePaymentRequest {
  cardNumber: string;
  stripeLocationId: string;
}

export interface StripeSimulatePaymentResponse {
}

export interface StripeGetPaymentIntentStatusRequest {
  paymentIntentId: string;
}

export interface StripeGetPaymentIntentStatusResponse {
  status: string;
}

export interface StripeCapturePaymentRequest {
  paymentIntentId: string;
  orderId: string;
}

export interface StripeCapturePaymentResponse {
  status: string;
}

function createBaseHandlePaymentRequest(): HandlePaymentRequest {
  return { amount: 0, currency: 0, kioskLocation: "", paymentProviderMetadata: undefined, orderId: "" };
}

export const HandlePaymentRequest = {
  encode(message: HandlePaymentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int64(message.amount);
    }
    if (message.currency !== 0) {
      writer.uint32(16).int32(message.currency);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(26).string(message.kioskLocation);
    }
    if (message.paymentProviderMetadata !== undefined) {
      Struct.encode(Struct.wrap(message.paymentProviderMetadata), writer.uint32(34).fork()).ldelim();
    }
    if (message.orderId !== "") {
      writer.uint32(42).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HandlePaymentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHandlePaymentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.paymentProviderMetadata = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HandlePaymentRequest {
    return {
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      currency: isSet(object.currency) ? currencyFromJSON(object.currency) : 0,
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
      paymentProviderMetadata: isObject(object.paymentProviderMetadata) ? object.paymentProviderMetadata : undefined,
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
    };
  },

  toJSON(message: HandlePaymentRequest): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.currency !== 0) {
      obj.currency = currencyToJSON(message.currency);
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    if (message.paymentProviderMetadata !== undefined) {
      obj.paymentProviderMetadata = message.paymentProviderMetadata;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HandlePaymentRequest>, I>>(base?: I): HandlePaymentRequest {
    return HandlePaymentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HandlePaymentRequest>, I>>(object: I): HandlePaymentRequest {
    const message = createBaseHandlePaymentRequest();
    message.amount = object.amount ?? 0;
    message.currency = object.currency ?? 0;
    message.kioskLocation = object.kioskLocation ?? "";
    message.paymentProviderMetadata = object.paymentProviderMetadata ?? undefined;
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseHandlePaymentResponse(): HandlePaymentResponse {
  return { isSuccessful: false, id: "" };
}

export const HandlePaymentResponse = {
  encode(message: HandlePaymentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isSuccessful === true) {
      writer.uint32(8).bool(message.isSuccessful);
    }
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HandlePaymentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHandlePaymentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isSuccessful = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HandlePaymentResponse {
    return {
      isSuccessful: isSet(object.isSuccessful) ? Boolean(object.isSuccessful) : false,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: HandlePaymentResponse): unknown {
    const obj: any = {};
    if (message.isSuccessful === true) {
      obj.isSuccessful = message.isSuccessful;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HandlePaymentResponse>, I>>(base?: I): HandlePaymentResponse {
    return HandlePaymentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HandlePaymentResponse>, I>>(object: I): HandlePaymentResponse {
    const message = createBaseHandlePaymentResponse();
    message.isSuccessful = object.isSuccessful ?? false;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseStripeGetConnectionTokenRequest(): StripeGetConnectionTokenRequest {
  return {};
}

export const StripeGetConnectionTokenRequest = {
  encode(_: StripeGetConnectionTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeGetConnectionTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeGetConnectionTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StripeGetConnectionTokenRequest {
    return {};
  },

  toJSON(_: StripeGetConnectionTokenRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeGetConnectionTokenRequest>, I>>(base?: I): StripeGetConnectionTokenRequest {
    return StripeGetConnectionTokenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeGetConnectionTokenRequest>, I>>(_: I): StripeGetConnectionTokenRequest {
    const message = createBaseStripeGetConnectionTokenRequest();
    return message;
  },
};

function createBaseStripeGetConnectionTokenResponse(): StripeGetConnectionTokenResponse {
  return { secret: "" };
}

export const StripeGetConnectionTokenResponse = {
  encode(message: StripeGetConnectionTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secret !== "") {
      writer.uint32(10).string(message.secret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeGetConnectionTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeGetConnectionTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.secret = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeGetConnectionTokenResponse {
    return { secret: isSet(object.secret) ? String(object.secret) : "" };
  },

  toJSON(message: StripeGetConnectionTokenResponse): unknown {
    const obj: any = {};
    if (message.secret !== "") {
      obj.secret = message.secret;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeGetConnectionTokenResponse>, I>>(
    base?: I,
  ): StripeGetConnectionTokenResponse {
    return StripeGetConnectionTokenResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeGetConnectionTokenResponse>, I>>(
    object: I,
  ): StripeGetConnectionTokenResponse {
    const message = createBaseStripeGetConnectionTokenResponse();
    message.secret = object.secret ?? "";
    return message;
  },
};

function createBaseStripeCreatePaymentIntentRequest(): StripeCreatePaymentIntentRequest {
  return { amount: 0, currency: 0 };
}

export const StripeCreatePaymentIntentRequest = {
  encode(message: StripeCreatePaymentIntentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int64(message.amount);
    }
    if (message.currency !== 0) {
      writer.uint32(16).int32(message.currency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCreatePaymentIntentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCreatePaymentIntentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCreatePaymentIntentRequest {
    return {
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      currency: isSet(object.currency) ? currencyFromJSON(object.currency) : 0,
    };
  },

  toJSON(message: StripeCreatePaymentIntentRequest): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.currency !== 0) {
      obj.currency = currencyToJSON(message.currency);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCreatePaymentIntentRequest>, I>>(
    base?: I,
  ): StripeCreatePaymentIntentRequest {
    return StripeCreatePaymentIntentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCreatePaymentIntentRequest>, I>>(
    object: I,
  ): StripeCreatePaymentIntentRequest {
    const message = createBaseStripeCreatePaymentIntentRequest();
    message.amount = object.amount ?? 0;
    message.currency = object.currency ?? 0;
    return message;
  },
};

function createBaseStripeCreatePaymentIntentResponse(): StripeCreatePaymentIntentResponse {
  return { clientSecret: "" };
}

export const StripeCreatePaymentIntentResponse = {
  encode(message: StripeCreatePaymentIntentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientSecret !== "") {
      writer.uint32(10).string(message.clientSecret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCreatePaymentIntentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCreatePaymentIntentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientSecret = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCreatePaymentIntentResponse {
    return { clientSecret: isSet(object.clientSecret) ? String(object.clientSecret) : "" };
  },

  toJSON(message: StripeCreatePaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.clientSecret !== "") {
      obj.clientSecret = message.clientSecret;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCreatePaymentIntentResponse>, I>>(
    base?: I,
  ): StripeCreatePaymentIntentResponse {
    return StripeCreatePaymentIntentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCreatePaymentIntentResponse>, I>>(
    object: I,
  ): StripeCreatePaymentIntentResponse {
    const message = createBaseStripeCreatePaymentIntentResponse();
    message.clientSecret = object.clientSecret ?? "";
    return message;
  },
};

function createBaseStripeCreateAndProcessPaymentIntentRequest(): StripeCreateAndProcessPaymentIntentRequest {
  return { amount: 0, currency: 0, stripeLocationId: "", stripeTerminalId: "" };
}

export const StripeCreateAndProcessPaymentIntentRequest = {
  encode(message: StripeCreateAndProcessPaymentIntentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int64(message.amount);
    }
    if (message.currency !== 0) {
      writer.uint32(16).int32(message.currency);
    }
    if (message.stripeLocationId !== "") {
      writer.uint32(26).string(message.stripeLocationId);
    }
    if (message.stripeTerminalId !== "") {
      writer.uint32(34).string(message.stripeTerminalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCreateAndProcessPaymentIntentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCreateAndProcessPaymentIntentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.amount = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.stripeLocationId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stripeTerminalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCreateAndProcessPaymentIntentRequest {
    return {
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      currency: isSet(object.currency) ? currencyFromJSON(object.currency) : 0,
      stripeLocationId: isSet(object.stripeLocationId) ? String(object.stripeLocationId) : "",
      stripeTerminalId: isSet(object.stripeTerminalId) ? String(object.stripeTerminalId) : "",
    };
  },

  toJSON(message: StripeCreateAndProcessPaymentIntentRequest): unknown {
    const obj: any = {};
    if (message.amount !== 0) {
      obj.amount = Math.round(message.amount);
    }
    if (message.currency !== 0) {
      obj.currency = currencyToJSON(message.currency);
    }
    if (message.stripeLocationId !== "") {
      obj.stripeLocationId = message.stripeLocationId;
    }
    if (message.stripeTerminalId !== "") {
      obj.stripeTerminalId = message.stripeTerminalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCreateAndProcessPaymentIntentRequest>, I>>(
    base?: I,
  ): StripeCreateAndProcessPaymentIntentRequest {
    return StripeCreateAndProcessPaymentIntentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCreateAndProcessPaymentIntentRequest>, I>>(
    object: I,
  ): StripeCreateAndProcessPaymentIntentRequest {
    const message = createBaseStripeCreateAndProcessPaymentIntentRequest();
    message.amount = object.amount ?? 0;
    message.currency = object.currency ?? 0;
    message.stripeLocationId = object.stripeLocationId ?? "";
    message.stripeTerminalId = object.stripeTerminalId ?? "";
    return message;
  },
};

function createBaseStripeCreateAndProcessPaymentIntentResponse(): StripeCreateAndProcessPaymentIntentResponse {
  return { paymentIntentId: "" };
}

export const StripeCreateAndProcessPaymentIntentResponse = {
  encode(message: StripeCreateAndProcessPaymentIntentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentIntentId !== "") {
      writer.uint32(10).string(message.paymentIntentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCreateAndProcessPaymentIntentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCreateAndProcessPaymentIntentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentIntentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCreateAndProcessPaymentIntentResponse {
    return { paymentIntentId: isSet(object.paymentIntentId) ? String(object.paymentIntentId) : "" };
  },

  toJSON(message: StripeCreateAndProcessPaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.paymentIntentId !== "") {
      obj.paymentIntentId = message.paymentIntentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCreateAndProcessPaymentIntentResponse>, I>>(
    base?: I,
  ): StripeCreateAndProcessPaymentIntentResponse {
    return StripeCreateAndProcessPaymentIntentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCreateAndProcessPaymentIntentResponse>, I>>(
    object: I,
  ): StripeCreateAndProcessPaymentIntentResponse {
    const message = createBaseStripeCreateAndProcessPaymentIntentResponse();
    message.paymentIntentId = object.paymentIntentId ?? "";
    return message;
  },
};

function createBaseStripeProcessPaymentIntentRequest(): StripeProcessPaymentIntentRequest {
  return { stripeLocationId: "", paymentIntentId: "" };
}

export const StripeProcessPaymentIntentRequest = {
  encode(message: StripeProcessPaymentIntentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stripeLocationId !== "") {
      writer.uint32(10).string(message.stripeLocationId);
    }
    if (message.paymentIntentId !== "") {
      writer.uint32(18).string(message.paymentIntentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeProcessPaymentIntentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeProcessPaymentIntentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.stripeLocationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentIntentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeProcessPaymentIntentRequest {
    return {
      stripeLocationId: isSet(object.stripeLocationId) ? String(object.stripeLocationId) : "",
      paymentIntentId: isSet(object.paymentIntentId) ? String(object.paymentIntentId) : "",
    };
  },

  toJSON(message: StripeProcessPaymentIntentRequest): unknown {
    const obj: any = {};
    if (message.stripeLocationId !== "") {
      obj.stripeLocationId = message.stripeLocationId;
    }
    if (message.paymentIntentId !== "") {
      obj.paymentIntentId = message.paymentIntentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeProcessPaymentIntentRequest>, I>>(
    base?: I,
  ): StripeProcessPaymentIntentRequest {
    return StripeProcessPaymentIntentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeProcessPaymentIntentRequest>, I>>(
    object: I,
  ): StripeProcessPaymentIntentRequest {
    const message = createBaseStripeProcessPaymentIntentRequest();
    message.stripeLocationId = object.stripeLocationId ?? "";
    message.paymentIntentId = object.paymentIntentId ?? "";
    return message;
  },
};

function createBaseStripeProcessPaymentIntentResponse(): StripeProcessPaymentIntentResponse {
  return { paymentIntentId: "" };
}

export const StripeProcessPaymentIntentResponse = {
  encode(message: StripeProcessPaymentIntentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentIntentId !== "") {
      writer.uint32(10).string(message.paymentIntentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeProcessPaymentIntentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeProcessPaymentIntentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentIntentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeProcessPaymentIntentResponse {
    return { paymentIntentId: isSet(object.paymentIntentId) ? String(object.paymentIntentId) : "" };
  },

  toJSON(message: StripeProcessPaymentIntentResponse): unknown {
    const obj: any = {};
    if (message.paymentIntentId !== "") {
      obj.paymentIntentId = message.paymentIntentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeProcessPaymentIntentResponse>, I>>(
    base?: I,
  ): StripeProcessPaymentIntentResponse {
    return StripeProcessPaymentIntentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeProcessPaymentIntentResponse>, I>>(
    object: I,
  ): StripeProcessPaymentIntentResponse {
    const message = createBaseStripeProcessPaymentIntentResponse();
    message.paymentIntentId = object.paymentIntentId ?? "";
    return message;
  },
};

function createBaseStripeSimulatePaymentRequest(): StripeSimulatePaymentRequest {
  return { cardNumber: "", stripeLocationId: "" };
}

export const StripeSimulatePaymentRequest = {
  encode(message: StripeSimulatePaymentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cardNumber !== "") {
      writer.uint32(10).string(message.cardNumber);
    }
    if (message.stripeLocationId !== "") {
      writer.uint32(18).string(message.stripeLocationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeSimulatePaymentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeSimulatePaymentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cardNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stripeLocationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeSimulatePaymentRequest {
    return {
      cardNumber: isSet(object.cardNumber) ? String(object.cardNumber) : "",
      stripeLocationId: isSet(object.stripeLocationId) ? String(object.stripeLocationId) : "",
    };
  },

  toJSON(message: StripeSimulatePaymentRequest): unknown {
    const obj: any = {};
    if (message.cardNumber !== "") {
      obj.cardNumber = message.cardNumber;
    }
    if (message.stripeLocationId !== "") {
      obj.stripeLocationId = message.stripeLocationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeSimulatePaymentRequest>, I>>(base?: I): StripeSimulatePaymentRequest {
    return StripeSimulatePaymentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeSimulatePaymentRequest>, I>>(object: I): StripeSimulatePaymentRequest {
    const message = createBaseStripeSimulatePaymentRequest();
    message.cardNumber = object.cardNumber ?? "";
    message.stripeLocationId = object.stripeLocationId ?? "";
    return message;
  },
};

function createBaseStripeSimulatePaymentResponse(): StripeSimulatePaymentResponse {
  return {};
}

export const StripeSimulatePaymentResponse = {
  encode(_: StripeSimulatePaymentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeSimulatePaymentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeSimulatePaymentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): StripeSimulatePaymentResponse {
    return {};
  },

  toJSON(_: StripeSimulatePaymentResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeSimulatePaymentResponse>, I>>(base?: I): StripeSimulatePaymentResponse {
    return StripeSimulatePaymentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeSimulatePaymentResponse>, I>>(_: I): StripeSimulatePaymentResponse {
    const message = createBaseStripeSimulatePaymentResponse();
    return message;
  },
};

function createBaseStripeGetPaymentIntentStatusRequest(): StripeGetPaymentIntentStatusRequest {
  return { paymentIntentId: "" };
}

export const StripeGetPaymentIntentStatusRequest = {
  encode(message: StripeGetPaymentIntentStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentIntentId !== "") {
      writer.uint32(10).string(message.paymentIntentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeGetPaymentIntentStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeGetPaymentIntentStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentIntentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeGetPaymentIntentStatusRequest {
    return { paymentIntentId: isSet(object.paymentIntentId) ? String(object.paymentIntentId) : "" };
  },

  toJSON(message: StripeGetPaymentIntentStatusRequest): unknown {
    const obj: any = {};
    if (message.paymentIntentId !== "") {
      obj.paymentIntentId = message.paymentIntentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeGetPaymentIntentStatusRequest>, I>>(
    base?: I,
  ): StripeGetPaymentIntentStatusRequest {
    return StripeGetPaymentIntentStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeGetPaymentIntentStatusRequest>, I>>(
    object: I,
  ): StripeGetPaymentIntentStatusRequest {
    const message = createBaseStripeGetPaymentIntentStatusRequest();
    message.paymentIntentId = object.paymentIntentId ?? "";
    return message;
  },
};

function createBaseStripeGetPaymentIntentStatusResponse(): StripeGetPaymentIntentStatusResponse {
  return { status: "" };
}

export const StripeGetPaymentIntentStatusResponse = {
  encode(message: StripeGetPaymentIntentStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeGetPaymentIntentStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeGetPaymentIntentStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeGetPaymentIntentStatusResponse {
    return { status: isSet(object.status) ? String(object.status) : "" };
  },

  toJSON(message: StripeGetPaymentIntentStatusResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeGetPaymentIntentStatusResponse>, I>>(
    base?: I,
  ): StripeGetPaymentIntentStatusResponse {
    return StripeGetPaymentIntentStatusResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeGetPaymentIntentStatusResponse>, I>>(
    object: I,
  ): StripeGetPaymentIntentStatusResponse {
    const message = createBaseStripeGetPaymentIntentStatusResponse();
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseStripeCapturePaymentRequest(): StripeCapturePaymentRequest {
  return { paymentIntentId: "", orderId: "" };
}

export const StripeCapturePaymentRequest = {
  encode(message: StripeCapturePaymentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentIntentId !== "") {
      writer.uint32(10).string(message.paymentIntentId);
    }
    if (message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCapturePaymentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCapturePaymentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentIntentId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCapturePaymentRequest {
    return {
      paymentIntentId: isSet(object.paymentIntentId) ? String(object.paymentIntentId) : "",
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
    };
  },

  toJSON(message: StripeCapturePaymentRequest): unknown {
    const obj: any = {};
    if (message.paymentIntentId !== "") {
      obj.paymentIntentId = message.paymentIntentId;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCapturePaymentRequest>, I>>(base?: I): StripeCapturePaymentRequest {
    return StripeCapturePaymentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCapturePaymentRequest>, I>>(object: I): StripeCapturePaymentRequest {
    const message = createBaseStripeCapturePaymentRequest();
    message.paymentIntentId = object.paymentIntentId ?? "";
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseStripeCapturePaymentResponse(): StripeCapturePaymentResponse {
  return { status: "" };
}

export const StripeCapturePaymentResponse = {
  encode(message: StripeCapturePaymentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeCapturePaymentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeCapturePaymentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StripeCapturePaymentResponse {
    return { status: isSet(object.status) ? String(object.status) : "" };
  },

  toJSON(message: StripeCapturePaymentResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StripeCapturePaymentResponse>, I>>(base?: I): StripeCapturePaymentResponse {
    return StripeCapturePaymentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StripeCapturePaymentResponse>, I>>(object: I): StripeCapturePaymentResponse {
    const message = createBaseStripeCapturePaymentResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface Payment {
  HandlePayment(
    request: DeepPartial<HandlePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<HandlePaymentResponse>;
  StripeGetConnectionToken(
    request: DeepPartial<StripeGetConnectionTokenRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeGetConnectionTokenResponse>;
  StripeCreatePaymentIntent(
    request: DeepPartial<StripeCreatePaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCreatePaymentIntentResponse>;
  StripeGetPaymentIntentStatus(
    request: DeepPartial<StripeGetPaymentIntentStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeGetPaymentIntentStatusResponse>;
  StripeCreateAndProcessPaymentIntent(
    request: DeepPartial<StripeCreateAndProcessPaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCreateAndProcessPaymentIntentResponse>;
  StripeProcessPaymentIntent(
    request: DeepPartial<StripeProcessPaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeProcessPaymentIntentResponse>;
  StripeSimulatePayment(
    request: DeepPartial<StripeSimulatePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeSimulatePaymentResponse>;
  StripeCapturePayment(
    request: DeepPartial<StripeCapturePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCapturePaymentResponse>;
}

export class PaymentClientImpl implements Payment {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.HandlePayment = this.HandlePayment.bind(this);
    this.StripeGetConnectionToken = this.StripeGetConnectionToken.bind(this);
    this.StripeCreatePaymentIntent = this.StripeCreatePaymentIntent.bind(this);
    this.StripeGetPaymentIntentStatus = this.StripeGetPaymentIntentStatus.bind(this);
    this.StripeCreateAndProcessPaymentIntent = this.StripeCreateAndProcessPaymentIntent.bind(this);
    this.StripeProcessPaymentIntent = this.StripeProcessPaymentIntent.bind(this);
    this.StripeSimulatePayment = this.StripeSimulatePayment.bind(this);
    this.StripeCapturePayment = this.StripeCapturePayment.bind(this);
  }

  HandlePayment(
    request: DeepPartial<HandlePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<HandlePaymentResponse> {
    return this.rpc.unary(PaymentHandlePaymentDesc, HandlePaymentRequest.fromPartial(request), metadata, abortSignal);
  }

  StripeGetConnectionToken(
    request: DeepPartial<StripeGetConnectionTokenRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeGetConnectionTokenResponse> {
    return this.rpc.unary(
      PaymentStripeGetConnectionTokenDesc,
      StripeGetConnectionTokenRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeCreatePaymentIntent(
    request: DeepPartial<StripeCreatePaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCreatePaymentIntentResponse> {
    return this.rpc.unary(
      PaymentStripeCreatePaymentIntentDesc,
      StripeCreatePaymentIntentRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeGetPaymentIntentStatus(
    request: DeepPartial<StripeGetPaymentIntentStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeGetPaymentIntentStatusResponse> {
    return this.rpc.unary(
      PaymentStripeGetPaymentIntentStatusDesc,
      StripeGetPaymentIntentStatusRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeCreateAndProcessPaymentIntent(
    request: DeepPartial<StripeCreateAndProcessPaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCreateAndProcessPaymentIntentResponse> {
    return this.rpc.unary(
      PaymentStripeCreateAndProcessPaymentIntentDesc,
      StripeCreateAndProcessPaymentIntentRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeProcessPaymentIntent(
    request: DeepPartial<StripeProcessPaymentIntentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeProcessPaymentIntentResponse> {
    return this.rpc.unary(
      PaymentStripeProcessPaymentIntentDesc,
      StripeProcessPaymentIntentRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeSimulatePayment(
    request: DeepPartial<StripeSimulatePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeSimulatePaymentResponse> {
    return this.rpc.unary(
      PaymentStripeSimulatePaymentDesc,
      StripeSimulatePaymentRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  StripeCapturePayment(
    request: DeepPartial<StripeCapturePaymentRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<StripeCapturePaymentResponse> {
    return this.rpc.unary(
      PaymentStripeCapturePaymentDesc,
      StripeCapturePaymentRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const PaymentDesc = { serviceName: "digitalbutler.v1.Payment" };

export const PaymentHandlePaymentDesc: UnaryMethodDefinitionish = {
  methodName: "HandlePayment",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HandlePaymentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HandlePaymentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeGetConnectionTokenDesc: UnaryMethodDefinitionish = {
  methodName: "StripeGetConnectionToken",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeGetConnectionTokenRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeGetConnectionTokenResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeCreatePaymentIntentDesc: UnaryMethodDefinitionish = {
  methodName: "StripeCreatePaymentIntent",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeCreatePaymentIntentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeCreatePaymentIntentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeGetPaymentIntentStatusDesc: UnaryMethodDefinitionish = {
  methodName: "StripeGetPaymentIntentStatus",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeGetPaymentIntentStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeGetPaymentIntentStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeCreateAndProcessPaymentIntentDesc: UnaryMethodDefinitionish = {
  methodName: "StripeCreateAndProcessPaymentIntent",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeCreateAndProcessPaymentIntentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeCreateAndProcessPaymentIntentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeProcessPaymentIntentDesc: UnaryMethodDefinitionish = {
  methodName: "StripeProcessPaymentIntent",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeProcessPaymentIntentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeProcessPaymentIntentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeSimulatePaymentDesc: UnaryMethodDefinitionish = {
  methodName: "StripeSimulatePayment",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeSimulatePaymentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeSimulatePaymentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentStripeCapturePaymentDesc: UnaryMethodDefinitionish = {
  methodName: "StripeCapturePayment",
  service: PaymentDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeCapturePaymentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeCapturePaymentResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
