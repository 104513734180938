/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "digitalbutler.v1";

export interface OrderCreatedNotificationRequest {
  orderId: string;
  orderPageUrl: string;
}

export interface OrderCreatedNotificationResponse {
}

export interface BookingCreatedNotificationRequest {
  bookingId: string;
  bookingPageUrl: string;
}

export interface BookingCreatedNotificationResponse {
}

export interface FridgePickupCompletedRequest {
  orderId: string;
  pin: string;
}

function createBaseOrderCreatedNotificationRequest(): OrderCreatedNotificationRequest {
  return { orderId: "", orderPageUrl: "" };
}

export const OrderCreatedNotificationRequest = {
  encode(message: OrderCreatedNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.orderPageUrl !== "") {
      writer.uint32(18).string(message.orderPageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderCreatedNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderCreatedNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderPageUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderCreatedNotificationRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      orderPageUrl: isSet(object.orderPageUrl) ? String(object.orderPageUrl) : "",
    };
  },

  toJSON(message: OrderCreatedNotificationRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.orderPageUrl !== "") {
      obj.orderPageUrl = message.orderPageUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderCreatedNotificationRequest>, I>>(base?: I): OrderCreatedNotificationRequest {
    return OrderCreatedNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderCreatedNotificationRequest>, I>>(
    object: I,
  ): OrderCreatedNotificationRequest {
    const message = createBaseOrderCreatedNotificationRequest();
    message.orderId = object.orderId ?? "";
    message.orderPageUrl = object.orderPageUrl ?? "";
    return message;
  },
};

function createBaseOrderCreatedNotificationResponse(): OrderCreatedNotificationResponse {
  return {};
}

export const OrderCreatedNotificationResponse = {
  encode(_: OrderCreatedNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderCreatedNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderCreatedNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): OrderCreatedNotificationResponse {
    return {};
  },

  toJSON(_: OrderCreatedNotificationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderCreatedNotificationResponse>, I>>(
    base?: I,
  ): OrderCreatedNotificationResponse {
    return OrderCreatedNotificationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderCreatedNotificationResponse>, I>>(
    _: I,
  ): OrderCreatedNotificationResponse {
    const message = createBaseOrderCreatedNotificationResponse();
    return message;
  },
};

function createBaseBookingCreatedNotificationRequest(): BookingCreatedNotificationRequest {
  return { bookingId: "", bookingPageUrl: "" };
}

export const BookingCreatedNotificationRequest = {
  encode(message: BookingCreatedNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingId !== "") {
      writer.uint32(10).string(message.bookingId);
    }
    if (message.bookingPageUrl !== "") {
      writer.uint32(18).string(message.bookingPageUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingCreatedNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingCreatedNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bookingId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bookingPageUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BookingCreatedNotificationRequest {
    return {
      bookingId: isSet(object.bookingId) ? String(object.bookingId) : "",
      bookingPageUrl: isSet(object.bookingPageUrl) ? String(object.bookingPageUrl) : "",
    };
  },

  toJSON(message: BookingCreatedNotificationRequest): unknown {
    const obj: any = {};
    if (message.bookingId !== "") {
      obj.bookingId = message.bookingId;
    }
    if (message.bookingPageUrl !== "") {
      obj.bookingPageUrl = message.bookingPageUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingCreatedNotificationRequest>, I>>(
    base?: I,
  ): BookingCreatedNotificationRequest {
    return BookingCreatedNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BookingCreatedNotificationRequest>, I>>(
    object: I,
  ): BookingCreatedNotificationRequest {
    const message = createBaseBookingCreatedNotificationRequest();
    message.bookingId = object.bookingId ?? "";
    message.bookingPageUrl = object.bookingPageUrl ?? "";
    return message;
  },
};

function createBaseBookingCreatedNotificationResponse(): BookingCreatedNotificationResponse {
  return {};
}

export const BookingCreatedNotificationResponse = {
  encode(_: BookingCreatedNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookingCreatedNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBookingCreatedNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): BookingCreatedNotificationResponse {
    return {};
  },

  toJSON(_: BookingCreatedNotificationResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<BookingCreatedNotificationResponse>, I>>(
    base?: I,
  ): BookingCreatedNotificationResponse {
    return BookingCreatedNotificationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BookingCreatedNotificationResponse>, I>>(
    _: I,
  ): BookingCreatedNotificationResponse {
    const message = createBaseBookingCreatedNotificationResponse();
    return message;
  },
};

function createBaseFridgePickupCompletedRequest(): FridgePickupCompletedRequest {
  return { orderId: "", pin: "" };
}

export const FridgePickupCompletedRequest = {
  encode(message: FridgePickupCompletedRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.pin !== "") {
      writer.uint32(18).string(message.pin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FridgePickupCompletedRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFridgePickupCompletedRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pin = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FridgePickupCompletedRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      pin: isSet(object.pin) ? String(object.pin) : "",
    };
  },

  toJSON(message: FridgePickupCompletedRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.pin !== "") {
      obj.pin = message.pin;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FridgePickupCompletedRequest>, I>>(base?: I): FridgePickupCompletedRequest {
    return FridgePickupCompletedRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FridgePickupCompletedRequest>, I>>(object: I): FridgePickupCompletedRequest {
    const message = createBaseFridgePickupCompletedRequest();
    message.orderId = object.orderId ?? "";
    message.pin = object.pin ?? "";
    return message;
  },
};

export interface Notification {
  HandleOrderCreated(
    request: DeepPartial<OrderCreatedNotificationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<OrderCreatedNotificationResponse>;
  HandleBookingCreated(
    request: DeepPartial<BookingCreatedNotificationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BookingCreatedNotificationResponse>;
}

export class NotificationClientImpl implements Notification {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.HandleOrderCreated = this.HandleOrderCreated.bind(this);
    this.HandleBookingCreated = this.HandleBookingCreated.bind(this);
  }

  HandleOrderCreated(
    request: DeepPartial<OrderCreatedNotificationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<OrderCreatedNotificationResponse> {
    return this.rpc.unary(
      NotificationHandleOrderCreatedDesc,
      OrderCreatedNotificationRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  HandleBookingCreated(
    request: DeepPartial<BookingCreatedNotificationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BookingCreatedNotificationResponse> {
    return this.rpc.unary(
      NotificationHandleBookingCreatedDesc,
      BookingCreatedNotificationRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const NotificationDesc = { serviceName: "digitalbutler.v1.Notification" };

export const NotificationHandleOrderCreatedDesc: UnaryMethodDefinitionish = {
  methodName: "HandleOrderCreated",
  service: NotificationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return OrderCreatedNotificationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = OrderCreatedNotificationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const NotificationHandleBookingCreatedDesc: UnaryMethodDefinitionish = {
  methodName: "HandleBookingCreated",
  service: NotificationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return BookingCreatedNotificationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BookingCreatedNotificationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
