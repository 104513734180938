import {
  GrpcWebImpl as OrderRPC,
  OrderClientImpl as OrderClient,
} from "grpc/v1/orderapi/order";
import {
  GrpcWebImpl as OrderOrchestrationRPC,
  OrderOrchestrationClientImpl as OrderOrchestrationClient,
} from "grpc/v1/orderorchestrationapi/orderorchestration";
import {
  AiPromptClientImpl as AipromptClient,
  GrpcWebImpl as AiPromptRPC,
} from "grpc/v1/aipromptapi/aiprompt";
import {
  CatalogClientImpl as CatalogClient,
  GrpcWebImpl as CatalogRPC,
} from "grpc/v1/catalogapi/catalog";
import {
  PaymentClientImpl as PaymentClient,
  GrpcWebImpl as PaymentRPC,
} from "grpc/v1/paymentapi/payment";
import {
  LocationClientImpl as LocationClient,
  GrpcWebImpl as LocationRPC,
} from "grpc/v1/locationapi/location";
import {
  EventClientImpl as EventClient,
  GrpcWebImpl as EventRPC,
} from "grpc/v1/eventapi/event";
import {
  NotificationClientImpl as NotificationClient,
  GrpcWebImpl as NotificationRPC,
} from "grpc/v1/notificationapi/notification";
import {
  BookingServiceClientImpl as BookingClient,
  GrpcWebImpl as BookingRPC,
} from "grpc/v1/bookingapi/booking";
import {
  VehicleServiceClientImpl as VehicleClient,
  GrpcWebImpl as VehicleRPC,
} from "grpc/v1/vehicleapi/vehicle";
import {
  PrinterConnectionClientImpl as PrinterConnectionClient,
  GrpcWebImpl as PrintRPC,
} from "grpc/v1/printerconnectionapi/printerconnection";
import {
  KioskClientImpl as KioskClient,
  GrpcWebImpl as KioskRPC,
} from "grpc/v1/kioskapi/kiosk";
import {
  LivelloFridgeClientImpl as FridgeClient,
  GrpcWebImpl as FridgeRPC,
} from "grpc/v1/fridgeapi/livellofridge";

const host =
  process.env.NODE_ENV === "production"
    ? "/api/v1"
    : `http://localhost:${process.env.REACT_APP_GRPC_PUBLIC_V1_WEB_PORT}`;

export const orderClient = new OrderClient(new OrderRPC(host, {}));

export const orderOrchestrationClient = new OrderOrchestrationClient(
  new OrderOrchestrationRPC(host, {})
);

export const aipromptClient = new AipromptClient(new AiPromptRPC(host, {}));

export const catalogClient = new CatalogClient(new CatalogRPC(host, {}));

export const paymentClient = new PaymentClient(new PaymentRPC(host, {}));

export const locationClient = new LocationClient(new LocationRPC(host, {}));

export const eventClient = new EventClient(new EventRPC(host, {}));

export const notificationClient = new NotificationClient(
  new NotificationRPC(host, {})
);

export const bookingClient = new BookingClient(new BookingRPC(host, {}));

export const vehicleClient = new VehicleClient(new VehicleRPC(host, {}));

export const printClient = new PrinterConnectionClient(new PrintRPC(host, {}));

export const kioskClient = new KioskClient(new KioskRPC(host, {}));

export const fridgeClient = new FridgeClient(new FridgeRPC(host, {}));
