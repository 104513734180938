/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "digitalbutler.v1";

export enum FridgeDoorStatus {
  UNDEFINED = 0,
  OPEN = 1,
  CLOSED = 2,
  UNRECOGNIZED = -1,
}

export function fridgeDoorStatusFromJSON(object: any): FridgeDoorStatus {
  switch (object) {
    case 0:
    case "UNDEFINED":
      return FridgeDoorStatus.UNDEFINED;
    case 1:
    case "OPEN":
      return FridgeDoorStatus.OPEN;
    case 2:
    case "CLOSED":
      return FridgeDoorStatus.CLOSED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FridgeDoorStatus.UNRECOGNIZED;
  }
}

export function fridgeDoorStatusToJSON(object: FridgeDoorStatus): string {
  switch (object) {
    case FridgeDoorStatus.UNDEFINED:
      return "UNDEFINED";
    case FridgeDoorStatus.OPEN:
      return "OPEN";
    case FridgeDoorStatus.CLOSED:
      return "CLOSED";
    case FridgeDoorStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface DoorStatusRequest {
  kioskLocation: string;
}

export interface DoorStatusResponse {
  doorStatus: FridgeDoorStatus;
}

export interface GenerateOTPRequest {
  kioskLocation: string;
  duration: number;
}

export interface GenerateOTPResponse {
  token: string;
  /** Duration of OTP validity in seconds */
  duration: number;
  used: boolean;
  createdBy: string;
}

export interface GetOTPRequest {
  orderId: string;
}

export interface GetOTPResponse {
  token: string;
  duration: number;
  used: boolean;
  createdBy: string;
}

function createBaseDoorStatusRequest(): DoorStatusRequest {
  return { kioskLocation: "" };
}

export const DoorStatusRequest = {
  encode(message: DoorStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoorStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoorStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoorStatusRequest {
    return { kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "" };
  },

  toJSON(message: DoorStatusRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoorStatusRequest>, I>>(base?: I): DoorStatusRequest {
    return DoorStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DoorStatusRequest>, I>>(object: I): DoorStatusRequest {
    const message = createBaseDoorStatusRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseDoorStatusResponse(): DoorStatusResponse {
  return { doorStatus: 0 };
}

export const DoorStatusResponse = {
  encode(message: DoorStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.doorStatus !== 0) {
      writer.uint32(8).int32(message.doorStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DoorStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDoorStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.doorStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DoorStatusResponse {
    return { doorStatus: isSet(object.doorStatus) ? fridgeDoorStatusFromJSON(object.doorStatus) : 0 };
  },

  toJSON(message: DoorStatusResponse): unknown {
    const obj: any = {};
    if (message.doorStatus !== 0) {
      obj.doorStatus = fridgeDoorStatusToJSON(message.doorStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DoorStatusResponse>, I>>(base?: I): DoorStatusResponse {
    return DoorStatusResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DoorStatusResponse>, I>>(object: I): DoorStatusResponse {
    const message = createBaseDoorStatusResponse();
    message.doorStatus = object.doorStatus ?? 0;
    return message;
  },
};

function createBaseGenerateOTPRequest(): GenerateOTPRequest {
  return { kioskLocation: "", duration: 0 };
}

export const GenerateOTPRequest = {
  encode(message: GenerateOTPRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    if (message.duration !== 0) {
      writer.uint32(16).int32(message.duration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateOTPRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateOTPRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.duration = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateOTPRequest {
    return {
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
      duration: isSet(object.duration) ? Number(object.duration) : 0,
    };
  },

  toJSON(message: GenerateOTPRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateOTPRequest>, I>>(base?: I): GenerateOTPRequest {
    return GenerateOTPRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateOTPRequest>, I>>(object: I): GenerateOTPRequest {
    const message = createBaseGenerateOTPRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    message.duration = object.duration ?? 0;
    return message;
  },
};

function createBaseGenerateOTPResponse(): GenerateOTPResponse {
  return { token: "", duration: 0, used: false, createdBy: "" };
}

export const GenerateOTPResponse = {
  encode(message: GenerateOTPResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.duration !== 0) {
      writer.uint32(16).int32(message.duration);
    }
    if (message.used === true) {
      writer.uint32(24).bool(message.used);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateOTPResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateOTPResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.used = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GenerateOTPResponse {
    return {
      token: isSet(object.token) ? String(object.token) : "",
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      used: isSet(object.used) ? Boolean(object.used) : false,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : "",
    };
  },

  toJSON(message: GenerateOTPResponse): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.used === true) {
      obj.used = message.used;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GenerateOTPResponse>, I>>(base?: I): GenerateOTPResponse {
    return GenerateOTPResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateOTPResponse>, I>>(object: I): GenerateOTPResponse {
    const message = createBaseGenerateOTPResponse();
    message.token = object.token ?? "";
    message.duration = object.duration ?? 0;
    message.used = object.used ?? false;
    message.createdBy = object.createdBy ?? "";
    return message;
  },
};

function createBaseGetOTPRequest(): GetOTPRequest {
  return { orderId: "" };
}

export const GetOTPRequest = {
  encode(message: GetOTPRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOTPRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOTPRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOTPRequest {
    return { orderId: isSet(object.orderId) ? String(object.orderId) : "" };
  },

  toJSON(message: GetOTPRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOTPRequest>, I>>(base?: I): GetOTPRequest {
    return GetOTPRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOTPRequest>, I>>(object: I): GetOTPRequest {
    const message = createBaseGetOTPRequest();
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseGetOTPResponse(): GetOTPResponse {
  return { token: "", duration: 0, used: false, createdBy: "" };
}

export const GetOTPResponse = {
  encode(message: GetOTPResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.duration !== 0) {
      writer.uint32(16).int32(message.duration);
    }
    if (message.used === true) {
      writer.uint32(24).bool(message.used);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOTPResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOTPResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.duration = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.used = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOTPResponse {
    return {
      token: isSet(object.token) ? String(object.token) : "",
      duration: isSet(object.duration) ? Number(object.duration) : 0,
      used: isSet(object.used) ? Boolean(object.used) : false,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : "",
    };
  },

  toJSON(message: GetOTPResponse): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.duration !== 0) {
      obj.duration = Math.round(message.duration);
    }
    if (message.used === true) {
      obj.used = message.used;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOTPResponse>, I>>(base?: I): GetOTPResponse {
    return GetOTPResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOTPResponse>, I>>(object: I): GetOTPResponse {
    const message = createBaseGetOTPResponse();
    message.token = object.token ?? "";
    message.duration = object.duration ?? 0;
    message.used = object.used ?? false;
    message.createdBy = object.createdBy ?? "";
    return message;
  },
};

export interface LivelloFridge {
  GenerateOTP(
    request: DeepPartial<GenerateOTPRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GenerateOTPResponse>;
  GetOTP(
    request: DeepPartial<GetOTPRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetOTPResponse>;
  GetDoorStatus(
    request: DeepPartial<DoorStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<DoorStatusResponse>;
}

export class LivelloFridgeClientImpl implements LivelloFridge {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GenerateOTP = this.GenerateOTP.bind(this);
    this.GetOTP = this.GetOTP.bind(this);
    this.GetDoorStatus = this.GetDoorStatus.bind(this);
  }

  GenerateOTP(
    request: DeepPartial<GenerateOTPRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GenerateOTPResponse> {
    return this.rpc.unary(LivelloFridgeGenerateOTPDesc, GenerateOTPRequest.fromPartial(request), metadata, abortSignal);
  }

  GetOTP(
    request: DeepPartial<GetOTPRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetOTPResponse> {
    return this.rpc.unary(LivelloFridgeGetOTPDesc, GetOTPRequest.fromPartial(request), metadata, abortSignal);
  }

  GetDoorStatus(
    request: DeepPartial<DoorStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<DoorStatusResponse> {
    return this.rpc.unary(
      LivelloFridgeGetDoorStatusDesc,
      DoorStatusRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const LivelloFridgeDesc = { serviceName: "digitalbutler.v1.LivelloFridge" };

export const LivelloFridgeGenerateOTPDesc: UnaryMethodDefinitionish = {
  methodName: "GenerateOTP",
  service: LivelloFridgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateOTPRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateOTPResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LivelloFridgeGetOTPDesc: UnaryMethodDefinitionish = {
  methodName: "GetOTP",
  service: LivelloFridgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOTPRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOTPResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LivelloFridgeGetDoorStatusDesc: UnaryMethodDefinitionish = {
  methodName: "GetDoorStatus",
  service: LivelloFridgeDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DoorStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DoorStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
