import { eventClient } from "GrpcClients";
import { CreateEventResponse } from "grpc/v1/eventapi/event";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

/**
 * Analytics class handles analytic events.
 */
export class Analytics {
  /** Singleton instance */
  private static _instance: Analytics;

  /** Last location */
  private pageLocation = "/";

  /** Currently active session identifier */
  private sessionIdentifier = "";

  /** Currently active session start time */
  private sessionStartTime: moment.Moment = moment();

  /** Event name for clicks */
  private static readonly EVENT_CLICK = "click";

  /** Event name for session timeout */
  private static readonly EVENT_SESSION_TIMEOUT = "session_timeout";

  /** Event name for successful payment */
  private static readonly EVENT_PAYMENT_SUCCESS = "payment_success";

  /** Event name for payment failures */
  private static readonly EVENT_PAYMENT_FAILED = "payment_failed";

  /** Event name for payment failures */
  private static readonly EVENT_NEW_SESSION = "new_session";

  /** Event name for page abandonment */
  private static readonly EVENT_ABANDONED = "abandoned";

  /** Event name for session end */
  private static readonly EVENT_SESSION_END_DURATION = "session_end_duration";

  /** Event name for session timeout prompt being opened */
  private static readonly EVENT_SESSION_TIMEOUT_PROMPT_OPENED =
    "session_timeout_prompt_open";

  /** Event name for session timeout prompt being manually dismissed */
  private static readonly EVENT_SESSION_TIMEOUT_PROMPT_DISMISS =
    "session_timeout_prompt_dismiss";

  /** Event for page view */
  private static readonly EVENT_PAGE_VIEW = "page_view";

  /**
   * Returns the singleton instance. Initializes the singleton if it hasn't been
   * set already.
   */
  public static get Instance(): Analytics {
    return this._instance || (this._instance = new this());
  }

  /**
   * Get client ID and push an event associated with the client ID.
   *
   * @param name event name
   * @param params optional parameters
   */
  private rawEvent(
    name: string,
    params: { [key: string]: string } = {},
    type = "behavior"
  ): Promise<CreateEventResponse> {
    const sessionDurationSeconds = moment().diff(this.sessionStartTime, "s");
    params = {
      ...params,
      session_id: this.sessionIdentifier,
      session_duration_s: sessionDurationSeconds.toString(),
      page_location: this.pageLocation,
    };

    // Emit event to Prometheus
    return eventClient.CreateEvent({
      name: type,
      meta: { ...params, event_name: name },
    });
  }

  /**
   * Emits a new session start event and resets the session ID.
   */
  public newSession() {
    this.rawEvent(Analytics.EVENT_NEW_SESSION).catch((error) =>
      console.error(error)
    );
    this.sessionIdentifier = uuidv4();
    this.sessionStartTime = moment();
  }

  /**
   * Event for non-interactive session timeout.
   */
  public sessionTimeout() {
    this.rawEvent(Analytics.EVENT_SESSION_TIMEOUT).catch((error) =>
      console.error(error)
    );
    this.abandoned("session_timed_out");
  }

  /**
   * Event for timeout prompt being opened.
   */
  public sessionTimeoutPromptOpened() {
    this.rawEvent(Analytics.EVENT_SESSION_TIMEOUT_PROMPT_OPENED).catch(
      (error) => console.error(error)
    );
  }

  /**
   * Event for timeout prompt being dismissed by the user.
   */
  public sessionTimeoutPromptDismiss() {
    this.rawEvent(Analytics.EVENT_SESSION_TIMEOUT_PROMPT_DISMISS).catch(
      (error) => console.error(error)
    );
  }

  /**
   * Emit a page view event.
   *
   * @param location full url of the page view
   * @param title page title for the page
   */
  public pageView(location: string, title: string) {
    this.pageLocation = location;
    this.rawEvent(Analytics.EVENT_PAGE_VIEW, {
      page_title: title,
    }).catch((error) => console.error(error));
  }

  /**
   * Emits a click event.
   *
   * @param key an identifier for the element that was clicked
   * @param context location of the clicked element
   */
  public click(key: string, context: string) {
    this.rawEvent(Analytics.EVENT_CLICK, {
      key: key,
      context: context,
    }).catch((error) => console.error(error));
  }

  /**
   * Emits an abandonment event. Only for internal use.
   *
   * @param reason reason why page was abandoned
   */
  private abandoned(reason: string) {
    this.rawEvent(Analytics.EVENT_ABANDONED, {
      reason: reason,
    }).catch((error) => console.error(error));
    this.sessionEndDuration();
  }

  private sessionEndDuration() {
    this.rawEvent(Analytics.EVENT_SESSION_END_DURATION).catch((error) =>
      console.error(error)
    );
  }

  /**
   * Emit events for when window is closed.
   */
  public windowClosed() {
    this.abandoned("window_closed");
  }

  /**
   * Emit a payment status event
   *
   * @param success true if payment was successful
   */
  public paymentStatus(success: boolean) {
    this.rawEvent(
      success ? Analytics.EVENT_PAYMENT_SUCCESS : Analytics.EVENT_PAYMENT_FAILED
    ).catch((error) => console.error(error));
  }

  /**
   * Initializes analytics with a given tracking ID. Loads the script, sets appen
   */
  public async init() {
    // Generate new session
    this.pageLocation = window.location.href;
  }
}

export default Analytics.Instance;
