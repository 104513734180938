import React from "react";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import "i18n/config";
import analytics from "common/analytics";
import { enableMapSet } from "immer";
import { notification } from "antd";
import loader from "common/loader";

if (process.env.NODE_ENV !== "production") {
  const logErr = console.error;
  console.error = (...errs) => {
    logErr(...errs);
    notification.error({
      message: "Error occurred",
      description: errs.join("; "),
      duration: 5,
    });
  };
}

enableMapSet();

analytics.init().then(() => {
  window.addEventListener("beforeunload", () => analytics.windowClosed(), {
    capture: true,
  });
});

// Check the current app version before loading React. If version number
// embedded in process.env is the same as reported by the backend, this promise
// will resolve.
//
// If not, it will clear cache and service workers and then reload, forcing a
// new version to be retrieved from the server.
//
// If the version is mismatched two times in a row, it will resolve the promise
// to prevent infinite reloads. This could happen due to a bug.
loader.checkVersion().then(() => {
  const appImports = [];

  switch (true) {
    case !!process.env.REACT_APP_BUILD_KIOSK_ONLY:
      appImports.push(import("./App"));
      break;
    case !!process.env.REACT_APP_BUILD_MOBILE_ONLY:
      appImports.push(import("./AppMobile"));
      break;
    default: {
      appImports.push(import("./App"), import("./AppMobile"));
      break;
    }
  }

  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);
  Promise.all(appImports).then((apps) => {
    root.render(
      <React.StrictMode>
        {apps.map((app) => (
          <Router key={app.default.name}>
            <app.default key={app.default.name} />
          </Router>
        ))}
      </React.StrictMode>
    );
  });
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then((reg) => {
      if (reg.installing) {
        loader.observe(reg.installing);
      } else if (reg.active) {
        loader.observe(reg.active);
      }
    });
}
