/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Vehicle } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface GetFromAuthoritiesRequest {
  registrationNumber: string;
}

export interface GetFromAuthoritiesResponse {
  vehicleInformation: Vehicle | undefined;
}

export interface GetAtLocationRequest {
  locationId: string;
}

export interface GetAtLocationResponse {
  vehicles: Vehicle[];
}

function createBaseGetFromAuthoritiesRequest(): GetFromAuthoritiesRequest {
  return { registrationNumber: "" };
}

export const GetFromAuthoritiesRequest = {
  encode(message: GetFromAuthoritiesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFromAuthoritiesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFromAuthoritiesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFromAuthoritiesRequest {
    return { registrationNumber: isSet(object.registrationNumber) ? String(object.registrationNumber) : "" };
  },

  toJSON(message: GetFromAuthoritiesRequest): unknown {
    const obj: any = {};
    if (message.registrationNumber !== "") {
      obj.registrationNumber = message.registrationNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFromAuthoritiesRequest>, I>>(base?: I): GetFromAuthoritiesRequest {
    return GetFromAuthoritiesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetFromAuthoritiesRequest>, I>>(object: I): GetFromAuthoritiesRequest {
    const message = createBaseGetFromAuthoritiesRequest();
    message.registrationNumber = object.registrationNumber ?? "";
    return message;
  },
};

function createBaseGetFromAuthoritiesResponse(): GetFromAuthoritiesResponse {
  return { vehicleInformation: undefined };
}

export const GetFromAuthoritiesResponse = {
  encode(message: GetFromAuthoritiesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vehicleInformation !== undefined) {
      Vehicle.encode(message.vehicleInformation, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFromAuthoritiesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFromAuthoritiesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vehicleInformation = Vehicle.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFromAuthoritiesResponse {
    return {
      vehicleInformation: isSet(object.vehicleInformation) ? Vehicle.fromJSON(object.vehicleInformation) : undefined,
    };
  },

  toJSON(message: GetFromAuthoritiesResponse): unknown {
    const obj: any = {};
    if (message.vehicleInformation !== undefined) {
      obj.vehicleInformation = Vehicle.toJSON(message.vehicleInformation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFromAuthoritiesResponse>, I>>(base?: I): GetFromAuthoritiesResponse {
    return GetFromAuthoritiesResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetFromAuthoritiesResponse>, I>>(object: I): GetFromAuthoritiesResponse {
    const message = createBaseGetFromAuthoritiesResponse();
    message.vehicleInformation = (object.vehicleInformation !== undefined && object.vehicleInformation !== null)
      ? Vehicle.fromPartial(object.vehicleInformation)
      : undefined;
    return message;
  },
};

function createBaseGetAtLocationRequest(): GetAtLocationRequest {
  return { locationId: "" };
}

export const GetAtLocationRequest = {
  encode(message: GetAtLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAtLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAtLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAtLocationRequest {
    return { locationId: isSet(object.locationId) ? String(object.locationId) : "" };
  },

  toJSON(message: GetAtLocationRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAtLocationRequest>, I>>(base?: I): GetAtLocationRequest {
    return GetAtLocationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAtLocationRequest>, I>>(object: I): GetAtLocationRequest {
    const message = createBaseGetAtLocationRequest();
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseGetAtLocationResponse(): GetAtLocationResponse {
  return { vehicles: [] };
}

export const GetAtLocationResponse = {
  encode(message: GetAtLocationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.vehicles) {
      Vehicle.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAtLocationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAtLocationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vehicles.push(Vehicle.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAtLocationResponse {
    return { vehicles: Array.isArray(object?.vehicles) ? object.vehicles.map((e: any) => Vehicle.fromJSON(e)) : [] };
  },

  toJSON(message: GetAtLocationResponse): unknown {
    const obj: any = {};
    if (message.vehicles?.length) {
      obj.vehicles = message.vehicles.map((e) => Vehicle.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAtLocationResponse>, I>>(base?: I): GetAtLocationResponse {
    return GetAtLocationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetAtLocationResponse>, I>>(object: I): GetAtLocationResponse {
    const message = createBaseGetAtLocationResponse();
    message.vehicles = object.vehicles?.map((e) => Vehicle.fromPartial(e)) || [];
    return message;
  },
};

export interface VehicleService {
  GetFromAuthorities(
    request: DeepPartial<GetFromAuthoritiesRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetFromAuthoritiesResponse>;
  GetAtLocation(
    request: DeepPartial<GetAtLocationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetAtLocationResponse>;
}

export class VehicleServiceClientImpl implements VehicleService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetFromAuthorities = this.GetFromAuthorities.bind(this);
    this.GetAtLocation = this.GetAtLocation.bind(this);
  }

  GetFromAuthorities(
    request: DeepPartial<GetFromAuthoritiesRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetFromAuthoritiesResponse> {
    return this.rpc.unary(
      VehicleServiceGetFromAuthoritiesDesc,
      GetFromAuthoritiesRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  GetAtLocation(
    request: DeepPartial<GetAtLocationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetAtLocationResponse> {
    return this.rpc.unary(
      VehicleServiceGetAtLocationDesc,
      GetAtLocationRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const VehicleServiceDesc = { serviceName: "digitalbutler.v1.VehicleService" };

export const VehicleServiceGetFromAuthoritiesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFromAuthorities",
  service: VehicleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFromAuthoritiesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFromAuthoritiesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const VehicleServiceGetAtLocationDesc: UnaryMethodDefinitionish = {
  methodName: "GetAtLocation",
  service: VehicleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAtLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAtLocationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
