/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

export const protobufPackage = "digitalbutler.v1";

export interface GetPrinterConnectionRequest {
  kioskLocation: string;
}

export interface GetPrinterConnectionResponse {
  keepAlive: number;
  printContent: string;
}

export interface PrintRequest {
  kioskLocation: string;
  message: string;
}

export interface PrintResponse {
  status: string;
}

function createBaseGetPrinterConnectionRequest(): GetPrinterConnectionRequest {
  return { kioskLocation: "" };
}

export const GetPrinterConnectionRequest = {
  encode(message: GetPrinterConnectionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPrinterConnectionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPrinterConnectionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPrinterConnectionRequest {
    return { kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "" };
  },

  toJSON(message: GetPrinterConnectionRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPrinterConnectionRequest>, I>>(base?: I): GetPrinterConnectionRequest {
    return GetPrinterConnectionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetPrinterConnectionRequest>, I>>(object: I): GetPrinterConnectionRequest {
    const message = createBaseGetPrinterConnectionRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetPrinterConnectionResponse(): GetPrinterConnectionResponse {
  return { keepAlive: 0, printContent: "" };
}

export const GetPrinterConnectionResponse = {
  encode(message: GetPrinterConnectionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keepAlive !== 0) {
      writer.uint32(8).int64(message.keepAlive);
    }
    if (message.printContent !== "") {
      writer.uint32(18).string(message.printContent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPrinterConnectionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPrinterConnectionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.keepAlive = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.printContent = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPrinterConnectionResponse {
    return {
      keepAlive: isSet(object.keepAlive) ? Number(object.keepAlive) : 0,
      printContent: isSet(object.printContent) ? String(object.printContent) : "",
    };
  },

  toJSON(message: GetPrinterConnectionResponse): unknown {
    const obj: any = {};
    if (message.keepAlive !== 0) {
      obj.keepAlive = Math.round(message.keepAlive);
    }
    if (message.printContent !== "") {
      obj.printContent = message.printContent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPrinterConnectionResponse>, I>>(base?: I): GetPrinterConnectionResponse {
    return GetPrinterConnectionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetPrinterConnectionResponse>, I>>(object: I): GetPrinterConnectionResponse {
    const message = createBaseGetPrinterConnectionResponse();
    message.keepAlive = object.keepAlive ?? 0;
    message.printContent = object.printContent ?? "";
    return message;
  },
};

function createBasePrintRequest(): PrintRequest {
  return { kioskLocation: "", message: "" };
}

export const PrintRequest = {
  encode(message: PrintRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrintRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrintRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrintRequest {
    return {
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: PrintRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrintRequest>, I>>(base?: I): PrintRequest {
    return PrintRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrintRequest>, I>>(object: I): PrintRequest {
    const message = createBasePrintRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBasePrintResponse(): PrintResponse {
  return { status: "" };
}

export const PrintResponse = {
  encode(message: PrintResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrintResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrintResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrintResponse {
    return { status: isSet(object.status) ? String(object.status) : "" };
  },

  toJSON(message: PrintResponse): unknown {
    const obj: any = {};
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrintResponse>, I>>(base?: I): PrintResponse {
    return PrintResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrintResponse>, I>>(object: I): PrintResponse {
    const message = createBasePrintResponse();
    message.status = object.status ?? "";
    return message;
  },
};

export interface PrinterConnection {
  PrintMessage(
    request: DeepPartial<PrintRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<PrintResponse>;
  GetPrinterConnectionStream(
    request: DeepPartial<GetPrinterConnectionRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Observable<GetPrinterConnectionResponse>;
}

export class PrinterConnectionClientImpl implements PrinterConnection {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.PrintMessage = this.PrintMessage.bind(this);
    this.GetPrinterConnectionStream = this.GetPrinterConnectionStream.bind(this);
  }

  PrintMessage(
    request: DeepPartial<PrintRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<PrintResponse> {
    return this.rpc.unary(PrinterConnectionPrintMessageDesc, PrintRequest.fromPartial(request), metadata, abortSignal);
  }

  GetPrinterConnectionStream(
    request: DeepPartial<GetPrinterConnectionRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Observable<GetPrinterConnectionResponse> {
    return this.rpc.invoke(
      PrinterConnectionGetPrinterConnectionStreamDesc,
      GetPrinterConnectionRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const PrinterConnectionDesc = { serviceName: "digitalbutler.v1.PrinterConnection" };

export const PrinterConnectionPrintMessageDesc: UnaryMethodDefinitionish = {
  methodName: "PrintMessage",
  service: PrinterConnectionDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PrintRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PrintResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PrinterConnectionGetPrinterConnectionStreamDesc: UnaryMethodDefinitionish = {
  methodName: "GetPrinterConnectionStream",
  service: PrinterConnectionDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return GetPrinterConnectionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetPrinterConnectionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = (() => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => {
          if (!abortSignal || !abortSignal.aborted) {
            return client.close();
          }
        });

        if (abortSignal) {
          abortSignal.addEventListener("abort", () => {
            observer.error(abortSignal.reason);
            client.close();
          });
        }
      });
      upStream();
    }).pipe(share());
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
