/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "digitalbutler.v1";

export interface AiPromptRequest {
  kioskLocation: string;
  prompt: string;
}

export interface AiPromptResponse {
  response: string;
}

export interface AiPromoRequest {
  kioskLocation: string;
}

export interface AiPromoResponse {
  promoMessage: string;
}

function createBaseAiPromptRequest(): AiPromptRequest {
  return { kioskLocation: "", prompt: "" };
}

export const AiPromptRequest = {
  encode(message: AiPromptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    if (message.prompt !== "") {
      writer.uint32(18).string(message.prompt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AiPromptRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAiPromptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.prompt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AiPromptRequest {
    return {
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
      prompt: isSet(object.prompt) ? String(object.prompt) : "",
    };
  },

  toJSON(message: AiPromptRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AiPromptRequest>, I>>(base?: I): AiPromptRequest {
    return AiPromptRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AiPromptRequest>, I>>(object: I): AiPromptRequest {
    const message = createBaseAiPromptRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    message.prompt = object.prompt ?? "";
    return message;
  },
};

function createBaseAiPromptResponse(): AiPromptResponse {
  return { response: "" };
}

export const AiPromptResponse = {
  encode(message: AiPromptResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== "") {
      writer.uint32(10).string(message.response);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AiPromptResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAiPromptResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.response = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AiPromptResponse {
    return { response: isSet(object.response) ? String(object.response) : "" };
  },

  toJSON(message: AiPromptResponse): unknown {
    const obj: any = {};
    if (message.response !== "") {
      obj.response = message.response;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AiPromptResponse>, I>>(base?: I): AiPromptResponse {
    return AiPromptResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AiPromptResponse>, I>>(object: I): AiPromptResponse {
    const message = createBaseAiPromptResponse();
    message.response = object.response ?? "";
    return message;
  },
};

function createBaseAiPromoRequest(): AiPromoRequest {
  return { kioskLocation: "" };
}

export const AiPromoRequest = {
  encode(message: AiPromoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocation !== "") {
      writer.uint32(10).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AiPromoRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAiPromoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AiPromoRequest {
    return { kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "" };
  },

  toJSON(message: AiPromoRequest): unknown {
    const obj: any = {};
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AiPromoRequest>, I>>(base?: I): AiPromoRequest {
    return AiPromoRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AiPromoRequest>, I>>(object: I): AiPromoRequest {
    const message = createBaseAiPromoRequest();
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseAiPromoResponse(): AiPromoResponse {
  return { promoMessage: "" };
}

export const AiPromoResponse = {
  encode(message: AiPromoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.promoMessage !== "") {
      writer.uint32(10).string(message.promoMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AiPromoResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAiPromoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.promoMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AiPromoResponse {
    return { promoMessage: isSet(object.promoMessage) ? String(object.promoMessage) : "" };
  },

  toJSON(message: AiPromoResponse): unknown {
    const obj: any = {};
    if (message.promoMessage !== "") {
      obj.promoMessage = message.promoMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AiPromoResponse>, I>>(base?: I): AiPromoResponse {
    return AiPromoResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AiPromoResponse>, I>>(object: I): AiPromoResponse {
    const message = createBaseAiPromoResponse();
    message.promoMessage = object.promoMessage ?? "";
    return message;
  },
};

export interface AiPrompt {
  CompletePrompt(
    request: DeepPartial<AiPromptRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<AiPromptResponse>;
  GetPromoMessage(
    request: DeepPartial<AiPromoRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<AiPromoResponse>;
}

export class AiPromptClientImpl implements AiPrompt {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CompletePrompt = this.CompletePrompt.bind(this);
    this.GetPromoMessage = this.GetPromoMessage.bind(this);
  }

  CompletePrompt(
    request: DeepPartial<AiPromptRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<AiPromptResponse> {
    return this.rpc.unary(AiPromptCompletePromptDesc, AiPromptRequest.fromPartial(request), metadata, abortSignal);
  }

  GetPromoMessage(
    request: DeepPartial<AiPromoRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<AiPromoResponse> {
    return this.rpc.unary(AiPromptGetPromoMessageDesc, AiPromoRequest.fromPartial(request), metadata, abortSignal);
  }
}

export const AiPromptDesc = { serviceName: "digitalbutler.v1.AiPrompt" };

export const AiPromptCompletePromptDesc: UnaryMethodDefinitionish = {
  methodName: "CompletePrompt",
  service: AiPromptDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AiPromptRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AiPromptResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AiPromptGetPromoMessageDesc: UnaryMethodDefinitionish = {
  methodName: "GetPromoMessage",
  service: AiPromptDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AiPromoRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AiPromoResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
