/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../google/protobuf/timestamp";
import { BasketResponse, Vehicle } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface Booking {
  id: string;
  locationId: string;
  vehicle: Vehicle | undefined;
  orderId: string;
  date: Date | undefined;
}

export interface CreateBookingRequest {
  locationId: string;
  orderId: string;
  vehicleInformation: Vehicle | undefined;
  date: Date | undefined;
  notificationsConfirmed: boolean;
}

export interface CreateBookingResponse {
  booking: Booking | undefined;
}

export interface HasBookingRequest {
  registrationNumber: string;
  locationId: string;
}

export interface HasBookingResponse {
  bookingPresent: boolean;
}

export interface GetBookingByRegistrationNumberRequest {
  registrationNumber: string;
  locationId: string;
  pin: number;
}

export interface GetBookingByRegistrationNumberResponse {
  booking: Booking | undefined;
  order: BasketResponse | undefined;
}

function createBaseBooking(): Booking {
  return { id: "", locationId: "", vehicle: undefined, orderId: "", date: undefined };
}

export const Booking = {
  encode(message: Booking, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.vehicle !== undefined) {
      Vehicle.encode(message.vehicle, writer.uint32(26).fork()).ldelim();
    }
    if (message.orderId !== "") {
      writer.uint32(34).string(message.orderId);
    }
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Booking {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBooking();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vehicle = Vehicle.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Booking {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      locationId: isSet(object.locationId) ? String(object.locationId) : "",
      vehicle: isSet(object.vehicle) ? Vehicle.fromJSON(object.vehicle) : undefined,
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
    };
  },

  toJSON(message: Booking): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.vehicle !== undefined) {
      obj.vehicle = Vehicle.toJSON(message.vehicle);
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Booking>, I>>(base?: I): Booking {
    return Booking.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Booking>, I>>(object: I): Booking {
    const message = createBaseBooking();
    message.id = object.id ?? "";
    message.locationId = object.locationId ?? "";
    message.vehicle = (object.vehicle !== undefined && object.vehicle !== null)
      ? Vehicle.fromPartial(object.vehicle)
      : undefined;
    message.orderId = object.orderId ?? "";
    message.date = object.date ?? undefined;
    return message;
  },
};

function createBaseCreateBookingRequest(): CreateBookingRequest {
  return { locationId: "", orderId: "", vehicleInformation: undefined, date: undefined, notificationsConfirmed: false };
}

export const CreateBookingRequest = {
  encode(message: CreateBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.locationId !== "") {
      writer.uint32(10).string(message.locationId);
    }
    if (message.orderId !== "") {
      writer.uint32(18).string(message.orderId);
    }
    if (message.vehicleInformation !== undefined) {
      Vehicle.encode(message.vehicleInformation, writer.uint32(26).fork()).ldelim();
    }
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(34).fork()).ldelim();
    }
    if (message.notificationsConfirmed === true) {
      writer.uint32(40).bool(message.notificationsConfirmed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vehicleInformation = Vehicle.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.notificationsConfirmed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBookingRequest {
    return {
      locationId: isSet(object.locationId) ? String(object.locationId) : "",
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      vehicleInformation: isSet(object.vehicleInformation) ? Vehicle.fromJSON(object.vehicleInformation) : undefined,
      date: isSet(object.date) ? fromJsonTimestamp(object.date) : undefined,
      notificationsConfirmed: isSet(object.notificationsConfirmed) ? Boolean(object.notificationsConfirmed) : false,
    };
  },

  toJSON(message: CreateBookingRequest): unknown {
    const obj: any = {};
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.vehicleInformation !== undefined) {
      obj.vehicleInformation = Vehicle.toJSON(message.vehicleInformation);
    }
    if (message.date !== undefined) {
      obj.date = message.date.toISOString();
    }
    if (message.notificationsConfirmed === true) {
      obj.notificationsConfirmed = message.notificationsConfirmed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBookingRequest>, I>>(base?: I): CreateBookingRequest {
    return CreateBookingRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateBookingRequest>, I>>(object: I): CreateBookingRequest {
    const message = createBaseCreateBookingRequest();
    message.locationId = object.locationId ?? "";
    message.orderId = object.orderId ?? "";
    message.vehicleInformation = (object.vehicleInformation !== undefined && object.vehicleInformation !== null)
      ? Vehicle.fromPartial(object.vehicleInformation)
      : undefined;
    message.date = object.date ?? undefined;
    message.notificationsConfirmed = object.notificationsConfirmed ?? false;
    return message;
  },
};

function createBaseCreateBookingResponse(): CreateBookingResponse {
  return { booking: undefined };
}

export const CreateBookingResponse = {
  encode(message: CreateBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      Booking.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = Booking.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateBookingResponse {
    return { booking: isSet(object.booking) ? Booking.fromJSON(object.booking) : undefined };
  },

  toJSON(message: CreateBookingResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = Booking.toJSON(message.booking);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateBookingResponse>, I>>(base?: I): CreateBookingResponse {
    return CreateBookingResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateBookingResponse>, I>>(object: I): CreateBookingResponse {
    const message = createBaseCreateBookingResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? Booking.fromPartial(object.booking)
      : undefined;
    return message;
  },
};

function createBaseHasBookingRequest(): HasBookingRequest {
  return { registrationNumber: "", locationId: "" };
}

export const HasBookingRequest = {
  encode(message: HasBookingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HasBookingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHasBookingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HasBookingRequest {
    return {
      registrationNumber: isSet(object.registrationNumber) ? String(object.registrationNumber) : "",
      locationId: isSet(object.locationId) ? String(object.locationId) : "",
    };
  },

  toJSON(message: HasBookingRequest): unknown {
    const obj: any = {};
    if (message.registrationNumber !== "") {
      obj.registrationNumber = message.registrationNumber;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HasBookingRequest>, I>>(base?: I): HasBookingRequest {
    return HasBookingRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HasBookingRequest>, I>>(object: I): HasBookingRequest {
    const message = createBaseHasBookingRequest();
    message.registrationNumber = object.registrationNumber ?? "";
    message.locationId = object.locationId ?? "";
    return message;
  },
};

function createBaseHasBookingResponse(): HasBookingResponse {
  return { bookingPresent: false };
}

export const HasBookingResponse = {
  encode(message: HasBookingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bookingPresent === true) {
      writer.uint32(8).bool(message.bookingPresent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HasBookingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHasBookingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bookingPresent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HasBookingResponse {
    return { bookingPresent: isSet(object.bookingPresent) ? Boolean(object.bookingPresent) : false };
  },

  toJSON(message: HasBookingResponse): unknown {
    const obj: any = {};
    if (message.bookingPresent === true) {
      obj.bookingPresent = message.bookingPresent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HasBookingResponse>, I>>(base?: I): HasBookingResponse {
    return HasBookingResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<HasBookingResponse>, I>>(object: I): HasBookingResponse {
    const message = createBaseHasBookingResponse();
    message.bookingPresent = object.bookingPresent ?? false;
    return message;
  },
};

function createBaseGetBookingByRegistrationNumberRequest(): GetBookingByRegistrationNumberRequest {
  return { registrationNumber: "", locationId: "", pin: 0 };
}

export const GetBookingByRegistrationNumberRequest = {
  encode(message: GetBookingByRegistrationNumberRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    if (message.locationId !== "") {
      writer.uint32(18).string(message.locationId);
    }
    if (message.pin !== 0) {
      writer.uint32(24).uint32(message.pin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingByRegistrationNumberRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingByRegistrationNumberRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locationId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pin = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingByRegistrationNumberRequest {
    return {
      registrationNumber: isSet(object.registrationNumber) ? String(object.registrationNumber) : "",
      locationId: isSet(object.locationId) ? String(object.locationId) : "",
      pin: isSet(object.pin) ? Number(object.pin) : 0,
    };
  },

  toJSON(message: GetBookingByRegistrationNumberRequest): unknown {
    const obj: any = {};
    if (message.registrationNumber !== "") {
      obj.registrationNumber = message.registrationNumber;
    }
    if (message.locationId !== "") {
      obj.locationId = message.locationId;
    }
    if (message.pin !== 0) {
      obj.pin = Math.round(message.pin);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingByRegistrationNumberRequest>, I>>(
    base?: I,
  ): GetBookingByRegistrationNumberRequest {
    return GetBookingByRegistrationNumberRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetBookingByRegistrationNumberRequest>, I>>(
    object: I,
  ): GetBookingByRegistrationNumberRequest {
    const message = createBaseGetBookingByRegistrationNumberRequest();
    message.registrationNumber = object.registrationNumber ?? "";
    message.locationId = object.locationId ?? "";
    message.pin = object.pin ?? 0;
    return message;
  },
};

function createBaseGetBookingByRegistrationNumberResponse(): GetBookingByRegistrationNumberResponse {
  return { booking: undefined, order: undefined };
}

export const GetBookingByRegistrationNumberResponse = {
  encode(message: GetBookingByRegistrationNumberResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.booking !== undefined) {
      Booking.encode(message.booking, writer.uint32(10).fork()).ldelim();
    }
    if (message.order !== undefined) {
      BasketResponse.encode(message.order, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBookingByRegistrationNumberResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBookingByRegistrationNumberResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.booking = Booking.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.order = BasketResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBookingByRegistrationNumberResponse {
    return {
      booking: isSet(object.booking) ? Booking.fromJSON(object.booking) : undefined,
      order: isSet(object.order) ? BasketResponse.fromJSON(object.order) : undefined,
    };
  },

  toJSON(message: GetBookingByRegistrationNumberResponse): unknown {
    const obj: any = {};
    if (message.booking !== undefined) {
      obj.booking = Booking.toJSON(message.booking);
    }
    if (message.order !== undefined) {
      obj.order = BasketResponse.toJSON(message.order);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBookingByRegistrationNumberResponse>, I>>(
    base?: I,
  ): GetBookingByRegistrationNumberResponse {
    return GetBookingByRegistrationNumberResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetBookingByRegistrationNumberResponse>, I>>(
    object: I,
  ): GetBookingByRegistrationNumberResponse {
    const message = createBaseGetBookingByRegistrationNumberResponse();
    message.booking = (object.booking !== undefined && object.booking !== null)
      ? Booking.fromPartial(object.booking)
      : undefined;
    message.order = (object.order !== undefined && object.order !== null)
      ? BasketResponse.fromPartial(object.order)
      : undefined;
    return message;
  },
};

export interface BookingService {
  CreateBooking(
    request: DeepPartial<CreateBookingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<CreateBookingResponse>;
  HasBooking(
    request: DeepPartial<HasBookingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<HasBookingResponse>;
  GetBookingByRegistrationNumber(
    request: DeepPartial<GetBookingByRegistrationNumberRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetBookingByRegistrationNumberResponse>;
}

export class BookingServiceClientImpl implements BookingService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateBooking = this.CreateBooking.bind(this);
    this.HasBooking = this.HasBooking.bind(this);
    this.GetBookingByRegistrationNumber = this.GetBookingByRegistrationNumber.bind(this);
  }

  CreateBooking(
    request: DeepPartial<CreateBookingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<CreateBookingResponse> {
    return this.rpc.unary(
      BookingServiceCreateBookingDesc,
      CreateBookingRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  HasBooking(
    request: DeepPartial<HasBookingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<HasBookingResponse> {
    return this.rpc.unary(BookingServiceHasBookingDesc, HasBookingRequest.fromPartial(request), metadata, abortSignal);
  }

  GetBookingByRegistrationNumber(
    request: DeepPartial<GetBookingByRegistrationNumberRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetBookingByRegistrationNumberResponse> {
    return this.rpc.unary(
      BookingServiceGetBookingByRegistrationNumberDesc,
      GetBookingByRegistrationNumberRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const BookingServiceDesc = { serviceName: "digitalbutler.v1.BookingService" };

export const BookingServiceCreateBookingDesc: UnaryMethodDefinitionish = {
  methodName: "CreateBooking",
  service: BookingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingServiceHasBookingDesc: UnaryMethodDefinitionish = {
  methodName: "HasBooking",
  service: BookingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HasBookingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HasBookingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const BookingServiceGetBookingByRegistrationNumberDesc: UnaryMethodDefinitionish = {
  methodName: "GetBookingByRegistrationNumber",
  service: BookingServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBookingByRegistrationNumberRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetBookingByRegistrationNumberResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
