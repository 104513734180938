import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en-GB",
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === "development",
    // necessary config option in order to use nested tags
    interpolation: {
      skipOnVariables: false,
    },
    resources: {
      "en-GB": {
        translations: require("./locales/en/translations.json"),
      },
      fr: {
        // french translations in the json file are prefixed with a 'f_' to aid testing
        // pending the time the words are professionally translated.
        translations: require("./locales/fr/translations.json"),
      },
      ar: {
        // arabic translations in the json file are prefixed with a 'a_' to aid testing
        // pending the time the words are professionally translated.
        translations: require("./locales/ar/translations.json"),
      },
      sk: {
        translations: require("./locales/sk/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ["en-GB", "fr", "ar", "sk"];

export default i18n;
export const t = i18n["t"];
