/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { Empty } from "../../google/protobuf/empty";
import { BasketResponse, OrderStatus, orderStatusFromJSON, orderStatusToJSON } from "../common/common";

export const protobufPackage = "digitalbutler.v1";

export interface SetPaymentIdRequest {
  orderId: string;
  paymentId: string;
}

export interface SetPaymentIdResponse {
  orderId: string;
}

export interface GetOrderRequest {
  orderId: string;
}

export interface GetOrderStepsRequest {
  orderId: string;
  kioskLocation: string;
}

export interface OrderDetails {
  step: number;
  detail: string;
}

export interface GetOrderStepsResponse {
  steps: OrderDetails[];
}

export interface GetOrderDetailsRequest {
  orderId: string;
  kioskLocation: string;
}

export interface GetOrderDetailsResponse {
  step: number;
}

export interface SetNameRequest {
  orderId: string;
  customerName?: string | undefined;
}

export interface SetPhoneRequest {
  orderId: string;
  phoneNumber?: string | undefined;
}

export interface SetEmailRequest {
  orderId: string;
  email?: string | undefined;
}

export interface GetBoughtTogetherRequest {
  catalogId: string;
  limit: number;
}

export interface GetBoughtTogetherResponse {
  catalogIds: string[];
}

export interface SetDataRequest {
  key: string;
  value: string;
}

export interface SetDataResponse {
  key: string;
  orderCount: number;
}

export interface GetDataRequest {
  key: string;
}

export interface GetDataResponse {
  key: string;
  value: string;
  orderCount: number;
}

export interface ChangeOrderStatusRequest {
  orderId: string;
  status: OrderStatus;
}

export interface SetDepositPaidRequest {
  orderId: string;
}

export interface SetRatingRequest {
  orderId: string;
  rating: number;
}

export interface SetRatingResponse {
}

function createBaseSetPaymentIdRequest(): SetPaymentIdRequest {
  return { orderId: "", paymentId: "" };
}

export const SetPaymentIdRequest = {
  encode(message: SetPaymentIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.paymentId !== "") {
      writer.uint32(18).string(message.paymentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPaymentIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPaymentIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPaymentIdRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      paymentId: isSet(object.paymentId) ? String(object.paymentId) : "",
    };
  },

  toJSON(message: SetPaymentIdRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.paymentId !== "") {
      obj.paymentId = message.paymentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPaymentIdRequest>, I>>(base?: I): SetPaymentIdRequest {
    return SetPaymentIdRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetPaymentIdRequest>, I>>(object: I): SetPaymentIdRequest {
    const message = createBaseSetPaymentIdRequest();
    message.orderId = object.orderId ?? "";
    message.paymentId = object.paymentId ?? "";
    return message;
  },
};

function createBaseSetPaymentIdResponse(): SetPaymentIdResponse {
  return { orderId: "" };
}

export const SetPaymentIdResponse = {
  encode(message: SetPaymentIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPaymentIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPaymentIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPaymentIdResponse {
    return { orderId: isSet(object.orderId) ? String(object.orderId) : "" };
  },

  toJSON(message: SetPaymentIdResponse): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPaymentIdResponse>, I>>(base?: I): SetPaymentIdResponse {
    return SetPaymentIdResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetPaymentIdResponse>, I>>(object: I): SetPaymentIdResponse {
    const message = createBaseSetPaymentIdResponse();
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseGetOrderRequest(): GetOrderRequest {
  return { orderId: "" };
}

export const GetOrderRequest = {
  encode(message: GetOrderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrderRequest {
    return { orderId: isSet(object.orderId) ? String(object.orderId) : "" };
  },

  toJSON(message: GetOrderRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrderRequest>, I>>(base?: I): GetOrderRequest {
    return GetOrderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOrderRequest>, I>>(object: I): GetOrderRequest {
    const message = createBaseGetOrderRequest();
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseGetOrderStepsRequest(): GetOrderStepsRequest {
  return { orderId: "", kioskLocation: "" };
}

export const GetOrderStepsRequest = {
  encode(message: GetOrderStepsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(18).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrderStepsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrderStepsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrderStepsRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: GetOrderStepsRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrderStepsRequest>, I>>(base?: I): GetOrderStepsRequest {
    return GetOrderStepsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOrderStepsRequest>, I>>(object: I): GetOrderStepsRequest {
    const message = createBaseGetOrderStepsRequest();
    message.orderId = object.orderId ?? "";
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseOrderDetails(): OrderDetails {
  return { step: 0, detail: "" };
}

export const OrderDetails = {
  encode(message: OrderDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.step !== 0) {
      writer.uint32(8).int32(message.step);
    }
    if (message.detail !== "") {
      writer.uint32(18).string(message.detail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.step = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.detail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderDetails {
    return {
      step: isSet(object.step) ? Number(object.step) : 0,
      detail: isSet(object.detail) ? String(object.detail) : "",
    };
  },

  toJSON(message: OrderDetails): unknown {
    const obj: any = {};
    if (message.step !== 0) {
      obj.step = Math.round(message.step);
    }
    if (message.detail !== "") {
      obj.detail = message.detail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderDetails>, I>>(base?: I): OrderDetails {
    return OrderDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderDetails>, I>>(object: I): OrderDetails {
    const message = createBaseOrderDetails();
    message.step = object.step ?? 0;
    message.detail = object.detail ?? "";
    return message;
  },
};

function createBaseGetOrderStepsResponse(): GetOrderStepsResponse {
  return { steps: [] };
}

export const GetOrderStepsResponse = {
  encode(message: GetOrderStepsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.steps) {
      OrderDetails.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrderStepsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrderStepsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.steps.push(OrderDetails.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrderStepsResponse {
    return { steps: Array.isArray(object?.steps) ? object.steps.map((e: any) => OrderDetails.fromJSON(e)) : [] };
  },

  toJSON(message: GetOrderStepsResponse): unknown {
    const obj: any = {};
    if (message.steps?.length) {
      obj.steps = message.steps.map((e) => OrderDetails.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrderStepsResponse>, I>>(base?: I): GetOrderStepsResponse {
    return GetOrderStepsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOrderStepsResponse>, I>>(object: I): GetOrderStepsResponse {
    const message = createBaseGetOrderStepsResponse();
    message.steps = object.steps?.map((e) => OrderDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetOrderDetailsRequest(): GetOrderDetailsRequest {
  return { orderId: "", kioskLocation: "" };
}

export const GetOrderDetailsRequest = {
  encode(message: GetOrderDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.kioskLocation !== "") {
      writer.uint32(18).string(message.kioskLocation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrderDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrderDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.kioskLocation = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrderDetailsRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      kioskLocation: isSet(object.kioskLocation) ? String(object.kioskLocation) : "",
    };
  },

  toJSON(message: GetOrderDetailsRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.kioskLocation !== "") {
      obj.kioskLocation = message.kioskLocation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrderDetailsRequest>, I>>(base?: I): GetOrderDetailsRequest {
    return GetOrderDetailsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOrderDetailsRequest>, I>>(object: I): GetOrderDetailsRequest {
    const message = createBaseGetOrderDetailsRequest();
    message.orderId = object.orderId ?? "";
    message.kioskLocation = object.kioskLocation ?? "";
    return message;
  },
};

function createBaseGetOrderDetailsResponse(): GetOrderDetailsResponse {
  return { step: 0 };
}

export const GetOrderDetailsResponse = {
  encode(message: GetOrderDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.step !== 0) {
      writer.uint32(8).int32(message.step);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOrderDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetOrderDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.step = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetOrderDetailsResponse {
    return { step: isSet(object.step) ? Number(object.step) : 0 };
  },

  toJSON(message: GetOrderDetailsResponse): unknown {
    const obj: any = {};
    if (message.step !== 0) {
      obj.step = Math.round(message.step);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetOrderDetailsResponse>, I>>(base?: I): GetOrderDetailsResponse {
    return GetOrderDetailsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetOrderDetailsResponse>, I>>(object: I): GetOrderDetailsResponse {
    const message = createBaseGetOrderDetailsResponse();
    message.step = object.step ?? 0;
    return message;
  },
};

function createBaseSetNameRequest(): SetNameRequest {
  return { orderId: "", customerName: undefined };
}

export const SetNameRequest = {
  encode(message: SetNameRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.customerName !== undefined) {
      writer.uint32(18).string(message.customerName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetNameRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetNameRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.customerName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetNameRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      customerName: isSet(object.customerName) ? String(object.customerName) : undefined,
    };
  },

  toJSON(message: SetNameRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.customerName !== undefined) {
      obj.customerName = message.customerName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetNameRequest>, I>>(base?: I): SetNameRequest {
    return SetNameRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetNameRequest>, I>>(object: I): SetNameRequest {
    const message = createBaseSetNameRequest();
    message.orderId = object.orderId ?? "";
    message.customerName = object.customerName ?? undefined;
    return message;
  },
};

function createBaseSetPhoneRequest(): SetPhoneRequest {
  return { orderId: "", phoneNumber: undefined };
}

export const SetPhoneRequest = {
  encode(message: SetPhoneRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.phoneNumber !== undefined) {
      writer.uint32(26).string(message.phoneNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPhoneRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPhoneRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetPhoneRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : undefined,
    };
  },

  toJSON(message: SetPhoneRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.phoneNumber !== undefined) {
      obj.phoneNumber = message.phoneNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetPhoneRequest>, I>>(base?: I): SetPhoneRequest {
    return SetPhoneRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetPhoneRequest>, I>>(object: I): SetPhoneRequest {
    const message = createBaseSetPhoneRequest();
    message.orderId = object.orderId ?? "";
    message.phoneNumber = object.phoneNumber ?? undefined;
    return message;
  },
};

function createBaseSetEmailRequest(): SetEmailRequest {
  return { orderId: "", email: undefined };
}

export const SetEmailRequest = {
  encode(message: SetEmailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.email !== undefined) {
      writer.uint32(34).string(message.email);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetEmailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetEmailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetEmailRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      email: isSet(object.email) ? String(object.email) : undefined,
    };
  },

  toJSON(message: SetEmailRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetEmailRequest>, I>>(base?: I): SetEmailRequest {
    return SetEmailRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetEmailRequest>, I>>(object: I): SetEmailRequest {
    const message = createBaseSetEmailRequest();
    message.orderId = object.orderId ?? "";
    message.email = object.email ?? undefined;
    return message;
  },
};

function createBaseGetBoughtTogetherRequest(): GetBoughtTogetherRequest {
  return { catalogId: "", limit: 0 };
}

export const GetBoughtTogetherRequest = {
  encode(message: GetBoughtTogetherRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.catalogId !== "") {
      writer.uint32(10).string(message.catalogId);
    }
    if (message.limit !== 0) {
      writer.uint32(16).int32(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBoughtTogetherRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBoughtTogetherRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.catalogId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBoughtTogetherRequest {
    return {
      catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
      limit: isSet(object.limit) ? Number(object.limit) : 0,
    };
  },

  toJSON(message: GetBoughtTogetherRequest): unknown {
    const obj: any = {};
    if (message.catalogId !== "") {
      obj.catalogId = message.catalogId;
    }
    if (message.limit !== 0) {
      obj.limit = Math.round(message.limit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBoughtTogetherRequest>, I>>(base?: I): GetBoughtTogetherRequest {
    return GetBoughtTogetherRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetBoughtTogetherRequest>, I>>(object: I): GetBoughtTogetherRequest {
    const message = createBaseGetBoughtTogetherRequest();
    message.catalogId = object.catalogId ?? "";
    message.limit = object.limit ?? 0;
    return message;
  },
};

function createBaseGetBoughtTogetherResponse(): GetBoughtTogetherResponse {
  return { catalogIds: [] };
}

export const GetBoughtTogetherResponse = {
  encode(message: GetBoughtTogetherResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.catalogIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBoughtTogetherResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBoughtTogetherResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.catalogIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBoughtTogetherResponse {
    return { catalogIds: Array.isArray(object?.catalogIds) ? object.catalogIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: GetBoughtTogetherResponse): unknown {
    const obj: any = {};
    if (message.catalogIds?.length) {
      obj.catalogIds = message.catalogIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBoughtTogetherResponse>, I>>(base?: I): GetBoughtTogetherResponse {
    return GetBoughtTogetherResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetBoughtTogetherResponse>, I>>(object: I): GetBoughtTogetherResponse {
    const message = createBaseGetBoughtTogetherResponse();
    message.catalogIds = object.catalogIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetDataRequest(): SetDataRequest {
  return { key: "", value: "" };
}

export const SetDataRequest = {
  encode(message: SetDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDataRequest {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: SetDataRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDataRequest>, I>>(base?: I): SetDataRequest {
    return SetDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetDataRequest>, I>>(object: I): SetDataRequest {
    const message = createBaseSetDataRequest();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSetDataResponse(): SetDataResponse {
  return { key: "", orderCount: 0 };
}

export const SetDataResponse = {
  encode(message: SetDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.orderCount !== 0) {
      writer.uint32(16).int64(message.orderCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.orderCount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDataResponse {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      orderCount: isSet(object.orderCount) ? Number(object.orderCount) : 0,
    };
  },

  toJSON(message: SetDataResponse): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.orderCount !== 0) {
      obj.orderCount = Math.round(message.orderCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDataResponse>, I>>(base?: I): SetDataResponse {
    return SetDataResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetDataResponse>, I>>(object: I): SetDataResponse {
    const message = createBaseSetDataResponse();
    message.key = object.key ?? "";
    message.orderCount = object.orderCount ?? 0;
    return message;
  },
};

function createBaseGetDataRequest(): GetDataRequest {
  return { key: "" };
}

export const GetDataRequest = {
  encode(message: GetDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDataRequest {
    return { key: isSet(object.key) ? String(object.key) : "" };
  },

  toJSON(message: GetDataRequest): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDataRequest>, I>>(base?: I): GetDataRequest {
    return GetDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDataRequest>, I>>(object: I): GetDataRequest {
    const message = createBaseGetDataRequest();
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseGetDataResponse(): GetDataResponse {
  return { key: "", value: "", orderCount: 0 };
}

export const GetDataResponse = {
  encode(message: GetDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    if (message.orderCount !== 0) {
      writer.uint32(24).int64(message.orderCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.orderCount = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDataResponse {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? String(object.value) : "",
      orderCount: isSet(object.orderCount) ? Number(object.orderCount) : 0,
    };
  },

  toJSON(message: GetDataResponse): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.orderCount !== 0) {
      obj.orderCount = Math.round(message.orderCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDataResponse>, I>>(base?: I): GetDataResponse {
    return GetDataResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDataResponse>, I>>(object: I): GetDataResponse {
    const message = createBaseGetDataResponse();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    message.orderCount = object.orderCount ?? 0;
    return message;
  },
};

function createBaseChangeOrderStatusRequest(): ChangeOrderStatusRequest {
  return { orderId: "", status: 0 };
}

export const ChangeOrderStatusRequest = {
  encode(message: ChangeOrderStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeOrderStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeOrderStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChangeOrderStatusRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      status: isSet(object.status) ? orderStatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: ChangeOrderStatusRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.status !== 0) {
      obj.status = orderStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChangeOrderStatusRequest>, I>>(base?: I): ChangeOrderStatusRequest {
    return ChangeOrderStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ChangeOrderStatusRequest>, I>>(object: I): ChangeOrderStatusRequest {
    const message = createBaseChangeOrderStatusRequest();
    message.orderId = object.orderId ?? "";
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseSetDepositPaidRequest(): SetDepositPaidRequest {
  return { orderId: "" };
}

export const SetDepositPaidRequest = {
  encode(message: SetDepositPaidRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetDepositPaidRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetDepositPaidRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetDepositPaidRequest {
    return { orderId: isSet(object.orderId) ? String(object.orderId) : "" };
  },

  toJSON(message: SetDepositPaidRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetDepositPaidRequest>, I>>(base?: I): SetDepositPaidRequest {
    return SetDepositPaidRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetDepositPaidRequest>, I>>(object: I): SetDepositPaidRequest {
    const message = createBaseSetDepositPaidRequest();
    message.orderId = object.orderId ?? "";
    return message;
  },
};

function createBaseSetRatingRequest(): SetRatingRequest {
  return { orderId: "", rating: 0 };
}

export const SetRatingRequest = {
  encode(message: SetRatingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    if (message.rating !== 0) {
      writer.uint32(16).int32(message.rating);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRatingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetRatingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rating = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetRatingRequest {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      rating: isSet(object.rating) ? Number(object.rating) : 0,
    };
  },

  toJSON(message: SetRatingRequest): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.rating !== 0) {
      obj.rating = Math.round(message.rating);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetRatingRequest>, I>>(base?: I): SetRatingRequest {
    return SetRatingRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetRatingRequest>, I>>(object: I): SetRatingRequest {
    const message = createBaseSetRatingRequest();
    message.orderId = object.orderId ?? "";
    message.rating = object.rating ?? 0;
    return message;
  },
};

function createBaseSetRatingResponse(): SetRatingResponse {
  return {};
}

export const SetRatingResponse = {
  encode(_: SetRatingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetRatingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetRatingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SetRatingResponse {
    return {};
  },

  toJSON(_: SetRatingResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SetRatingResponse>, I>>(base?: I): SetRatingResponse {
    return SetRatingResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SetRatingResponse>, I>>(_: I): SetRatingResponse {
    const message = createBaseSetRatingResponse();
    return message;
  },
};

export interface Order {
  SetPaymentId(
    request: DeepPartial<SetPaymentIdRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<SetPaymentIdResponse>;
  GetOrderSteps(
    request: DeepPartial<GetOrderStepsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetOrderStepsResponse>;
  GetOrderDetailStream(
    request: DeepPartial<GetOrderDetailsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Observable<GetOrderDetailsResponse>;
  SetName(request: DeepPartial<SetNameRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty>;
  SetPhone(request: DeepPartial<SetPhoneRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty>;
  SetEmail(request: DeepPartial<SetEmailRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty>;
  GetBoughtTogether(
    request: DeepPartial<GetBoughtTogetherRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetBoughtTogetherResponse>;
  GetOrder(
    request: DeepPartial<GetOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse>;
  ChangeOrderStatus(
    request: DeepPartial<ChangeOrderStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse>;
  SetDepositPaid(
    request: DeepPartial<SetDepositPaidRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse>;
  SetRating(
    request: DeepPartial<SetRatingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<SetRatingResponse>;
}

export class OrderClientImpl implements Order {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.SetPaymentId = this.SetPaymentId.bind(this);
    this.GetOrderSteps = this.GetOrderSteps.bind(this);
    this.GetOrderDetailStream = this.GetOrderDetailStream.bind(this);
    this.SetName = this.SetName.bind(this);
    this.SetPhone = this.SetPhone.bind(this);
    this.SetEmail = this.SetEmail.bind(this);
    this.GetBoughtTogether = this.GetBoughtTogether.bind(this);
    this.GetOrder = this.GetOrder.bind(this);
    this.ChangeOrderStatus = this.ChangeOrderStatus.bind(this);
    this.SetDepositPaid = this.SetDepositPaid.bind(this);
    this.SetRating = this.SetRating.bind(this);
  }

  SetPaymentId(
    request: DeepPartial<SetPaymentIdRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<SetPaymentIdResponse> {
    return this.rpc.unary(OrderSetPaymentIdDesc, SetPaymentIdRequest.fromPartial(request), metadata, abortSignal);
  }

  GetOrderSteps(
    request: DeepPartial<GetOrderStepsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetOrderStepsResponse> {
    return this.rpc.unary(OrderGetOrderStepsDesc, GetOrderStepsRequest.fromPartial(request), metadata, abortSignal);
  }

  GetOrderDetailStream(
    request: DeepPartial<GetOrderDetailsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Observable<GetOrderDetailsResponse> {
    return this.rpc.invoke(
      OrderGetOrderDetailStreamDesc,
      GetOrderDetailsRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  SetName(request: DeepPartial<SetNameRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty> {
    return this.rpc.unary(OrderSetNameDesc, SetNameRequest.fromPartial(request), metadata, abortSignal);
  }

  SetPhone(request: DeepPartial<SetPhoneRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty> {
    return this.rpc.unary(OrderSetPhoneDesc, SetPhoneRequest.fromPartial(request), metadata, abortSignal);
  }

  SetEmail(request: DeepPartial<SetEmailRequest>, metadata?: grpc.Metadata, abortSignal?: AbortSignal): Promise<Empty> {
    return this.rpc.unary(OrderSetEmailDesc, SetEmailRequest.fromPartial(request), metadata, abortSignal);
  }

  GetBoughtTogether(
    request: DeepPartial<GetBoughtTogetherRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetBoughtTogetherResponse> {
    return this.rpc.unary(
      OrderGetBoughtTogetherDesc,
      GetBoughtTogetherRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  GetOrder(
    request: DeepPartial<GetOrderRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse> {
    return this.rpc.unary(OrderGetOrderDesc, GetOrderRequest.fromPartial(request), metadata, abortSignal);
  }

  ChangeOrderStatus(
    request: DeepPartial<ChangeOrderStatusRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse> {
    return this.rpc.unary(
      OrderChangeOrderStatusDesc,
      ChangeOrderStatusRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }

  SetDepositPaid(
    request: DeepPartial<SetDepositPaidRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<BasketResponse> {
    return this.rpc.unary(OrderSetDepositPaidDesc, SetDepositPaidRequest.fromPartial(request), metadata, abortSignal);
  }

  SetRating(
    request: DeepPartial<SetRatingRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<SetRatingResponse> {
    return this.rpc.unary(OrderSetRatingDesc, SetRatingRequest.fromPartial(request), metadata, abortSignal);
  }
}

export const OrderDesc = { serviceName: "digitalbutler.v1.Order" };

export const OrderSetPaymentIdDesc: UnaryMethodDefinitionish = {
  methodName: "SetPaymentId",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPaymentIdRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetPaymentIdResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderGetOrderStepsDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrderSteps",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrderStepsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOrderStepsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderGetOrderDetailStreamDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrderDetailStream",
  service: OrderDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return GetOrderDetailsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetOrderDetailsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderSetNameDesc: UnaryMethodDefinitionish = {
  methodName: "SetName",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetNameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderSetPhoneDesc: UnaryMethodDefinitionish = {
  methodName: "SetPhone",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPhoneRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderSetEmailDesc: UnaryMethodDefinitionish = {
  methodName: "SetEmail",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetEmailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderGetBoughtTogetherDesc: UnaryMethodDefinitionish = {
  methodName: "GetBoughtTogether",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetBoughtTogetherRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetBoughtTogetherResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderGetOrderDesc: UnaryMethodDefinitionish = {
  methodName: "GetOrder",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetOrderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BasketResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderChangeOrderStatusDesc: UnaryMethodDefinitionish = {
  methodName: "ChangeOrderStatus",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChangeOrderStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BasketResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderSetDepositPaidDesc: UnaryMethodDefinitionish = {
  methodName: "SetDepositPaid",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetDepositPaidRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = BasketResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const OrderSetRatingDesc: UnaryMethodDefinitionish = {
  methodName: "SetRating",
  service: OrderDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetRatingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetRatingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Observable<any> {
    const upStreamCodes = this.options.upStreamRetryCodes ?? [];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const transport = this.options.streamingTransport ?? this.options.transport;
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Observable((observer) => {
      const upStream = (() => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          ...(transport !== undefined ? { transport } : {}),
          metadata: maybeCombinedMetadata ?? {},
          debug: this.options.debug ?? false,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              const err = new Error(message) as any;
              err.code = code;
              err.metadata = trailers;
              observer.error(err);
            }
          },
        });
        observer.add(() => {
          if (!abortSignal || !abortSignal.aborted) {
            return client.close();
          }
        });

        if (abortSignal) {
          abortSignal.addEventListener("abort", () => {
            observer.error(abortSignal.reason);
            client.close();
          });
        }
      });
      upStream();
    }).pipe(share());
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
