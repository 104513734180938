/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "../../google/protobuf/duration";
import { Timestamp } from "../../google/protobuf/timestamp";

export const protobufPackage = "digitalbutler.v1";

export enum Currency {
  UNSPECIFIED_CURRENCY = 0,
  GBP = 1,
  USD = 2,
  AUD = 3,
  /** ZAR - South African rand */
  ZAR = 4,
  UNRECOGNIZED = -1,
}

export function currencyFromJSON(object: any): Currency {
  switch (object) {
    case 0:
    case "UNSPECIFIED_CURRENCY":
      return Currency.UNSPECIFIED_CURRENCY;
    case 1:
    case "GBP":
      return Currency.GBP;
    case 2:
    case "USD":
      return Currency.USD;
    case 3:
    case "AUD":
      return Currency.AUD;
    case 4:
    case "ZAR":
      return Currency.ZAR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Currency.UNRECOGNIZED;
  }
}

export function currencyToJSON(object: Currency): string {
  switch (object) {
    case Currency.UNSPECIFIED_CURRENCY:
      return "UNSPECIFIED_CURRENCY";
    case Currency.GBP:
      return "GBP";
    case Currency.USD:
      return "USD";
    case Currency.AUD:
      return "AUD";
    case Currency.ZAR:
      return "ZAR";
    case Currency.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ItemType {
  Unspecified = 0,
  Simple = 1,
  Option = 2,
  Service = 3,
  ServiceRequired = 4,
  UNRECOGNIZED = -1,
}

export function itemTypeFromJSON(object: any): ItemType {
  switch (object) {
    case 0:
    case "Unspecified":
      return ItemType.Unspecified;
    case 1:
    case "Simple":
      return ItemType.Simple;
    case 2:
    case "Option":
      return ItemType.Option;
    case 3:
    case "Service":
      return ItemType.Service;
    case 4:
    case "ServiceRequired":
      return ItemType.ServiceRequired;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ItemType.UNRECOGNIZED;
  }
}

export function itemTypeToJSON(object: ItemType): string {
  switch (object) {
    case ItemType.Unspecified:
      return "Unspecified";
    case ItemType.Simple:
      return "Simple";
    case ItemType.Option:
      return "Option";
    case ItemType.Service:
      return "Service";
    case ItemType.ServiceRequired:
      return "ServiceRequired";
    case ItemType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum OrderStatus {
  INVALID = 0,
  COMPLETED = 1,
  ABANDONED = 2,
  CANCELLED = 3,
  IN_PROGRESS = 4,
  PENDING_PAYMENT = 5,
  BEING_PREPARED = 6,
  PAYMENT_COMPLETE = 7,
  KITCHEN_REJECTED = 8,
  FOR_COLLECTION = 9,
  UNRECOGNIZED = -1,
}

export function orderStatusFromJSON(object: any): OrderStatus {
  switch (object) {
    case 0:
    case "INVALID":
      return OrderStatus.INVALID;
    case 1:
    case "COMPLETED":
      return OrderStatus.COMPLETED;
    case 2:
    case "ABANDONED":
      return OrderStatus.ABANDONED;
    case 3:
    case "CANCELLED":
      return OrderStatus.CANCELLED;
    case 4:
    case "IN_PROGRESS":
      return OrderStatus.IN_PROGRESS;
    case 5:
    case "PENDING_PAYMENT":
      return OrderStatus.PENDING_PAYMENT;
    case 6:
    case "BEING_PREPARED":
      return OrderStatus.BEING_PREPARED;
    case 7:
    case "PAYMENT_COMPLETE":
      return OrderStatus.PAYMENT_COMPLETE;
    case 8:
    case "KITCHEN_REJECTED":
      return OrderStatus.KITCHEN_REJECTED;
    case 9:
    case "FOR_COLLECTION":
      return OrderStatus.FOR_COLLECTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OrderStatus.UNRECOGNIZED;
  }
}

export function orderStatusToJSON(object: OrderStatus): string {
  switch (object) {
    case OrderStatus.INVALID:
      return "INVALID";
    case OrderStatus.COMPLETED:
      return "COMPLETED";
    case OrderStatus.ABANDONED:
      return "ABANDONED";
    case OrderStatus.CANCELLED:
      return "CANCELLED";
    case OrderStatus.IN_PROGRESS:
      return "IN_PROGRESS";
    case OrderStatus.PENDING_PAYMENT:
      return "PENDING_PAYMENT";
    case OrderStatus.BEING_PREPARED:
      return "BEING_PREPARED";
    case OrderStatus.PAYMENT_COMPLETE:
      return "PAYMENT_COMPLETE";
    case OrderStatus.KITCHEN_REJECTED:
      return "KITCHEN_REJECTED";
    case OrderStatus.FOR_COLLECTION:
      return "FOR_COLLECTION";
    case OrderStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Vehicle {
  registrationNumber: string;
  make: string;
  color: string;
}

export interface Image {
  path: string;
  alt: string;
}

export interface Tag {
  id: string;
  name: string;
}

export interface CatalogItem {
  id: string;
  externalApiId?: string | undefined;
  catalogId: string;
  name: string;
  description?: string | undefined;
  priceInfo: number;
  image: Image | undefined;
  availableForSale: boolean;
  stockOnHand: number;
  totalOrdersPerItem: number;
  currency: Currency;
  subcategoryId: string;
  categoryId: string;
  attributes: { [key: string]: string };
  dependents: CatalogItem[];
  type: ItemType;
  tags: Tag[];
  descriptionShort: string;
}

export interface CatalogItem_AttributesEntry {
  key: string;
  value: string;
}

export interface BasicOrderItem {
  id: string;
  quantity: number;
}

export interface OrderItem {
  catalogItem: CatalogItem | undefined;
  quantity: number;
  priceSubtotal: number;
  description: string;
}

export interface BasketResponse {
  orderId: string;
  items: OrderItem[];
  totalPrice: number;
  status: OrderStatus;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  paymentId: string;
  friendlyId: number;
  deliveryAddress: string;
  kitchenNotes: string;
  collectionNotes: string;
  kioskLocationId: string;
  requireDeposit: boolean;
  deposit: number;
  isDepositPaid: boolean;
  amountToPay: number;
  notificationsConfirmed: boolean;
  customerName?: string | undefined;
  phoneNumber?: string | undefined;
  email?: string | undefined;
  orderDuration: Duration | undefined;
}

function createBaseVehicle(): Vehicle {
  return { registrationNumber: "", make: "", color: "" };
}

export const Vehicle = {
  encode(message: Vehicle, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber);
    }
    if (message.make !== "") {
      writer.uint32(18).string(message.make);
    }
    if (message.color !== "") {
      writer.uint32(26).string(message.color);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vehicle {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVehicle();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.registrationNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.make = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.color = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vehicle {
    return {
      registrationNumber: isSet(object.registrationNumber) ? String(object.registrationNumber) : "",
      make: isSet(object.make) ? String(object.make) : "",
      color: isSet(object.color) ? String(object.color) : "",
    };
  },

  toJSON(message: Vehicle): unknown {
    const obj: any = {};
    if (message.registrationNumber !== "") {
      obj.registrationNumber = message.registrationNumber;
    }
    if (message.make !== "") {
      obj.make = message.make;
    }
    if (message.color !== "") {
      obj.color = message.color;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vehicle>, I>>(base?: I): Vehicle {
    return Vehicle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Vehicle>, I>>(object: I): Vehicle {
    const message = createBaseVehicle();
    message.registrationNumber = object.registrationNumber ?? "";
    message.make = object.make ?? "";
    message.color = object.color ?? "";
    return message;
  },
};

function createBaseImage(): Image {
  return { path: "", alt: "" };
}

export const Image = {
  encode(message: Image, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.path !== "") {
      writer.uint32(10).string(message.path);
    }
    if (message.alt !== "") {
      writer.uint32(18).string(message.alt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Image {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.path = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.alt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Image {
    return { path: isSet(object.path) ? String(object.path) : "", alt: isSet(object.alt) ? String(object.alt) : "" };
  },

  toJSON(message: Image): unknown {
    const obj: any = {};
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.alt !== "") {
      obj.alt = message.alt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.path = object.path ?? "";
    message.alt = object.alt ?? "";
    return message;
  },
};

function createBaseTag(): Tag {
  return { id: "", name: "" };
}

export const Tag = {
  encode(message: Tag, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tag {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tag {
    return { id: isSet(object.id) ? String(object.id) : "", name: isSet(object.name) ? String(object.name) : "" };
  },

  toJSON(message: Tag): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tag>, I>>(base?: I): Tag {
    return Tag.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Tag>, I>>(object: I): Tag {
    const message = createBaseTag();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCatalogItem(): CatalogItem {
  return {
    id: "",
    externalApiId: undefined,
    catalogId: "",
    name: "",
    description: undefined,
    priceInfo: 0,
    image: undefined,
    availableForSale: false,
    stockOnHand: 0,
    totalOrdersPerItem: 0,
    currency: 0,
    subcategoryId: "",
    categoryId: "",
    attributes: {},
    dependents: [],
    type: 0,
    tags: [],
    descriptionShort: "",
  };
}

export const CatalogItem = {
  encode(message: CatalogItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.externalApiId !== undefined) {
      writer.uint32(18).string(message.externalApiId);
    }
    if (message.catalogId !== "") {
      writer.uint32(26).string(message.catalogId);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.description !== undefined) {
      writer.uint32(42).string(message.description);
    }
    if (message.priceInfo !== 0) {
      writer.uint32(48).int64(message.priceInfo);
    }
    if (message.image !== undefined) {
      Image.encode(message.image, writer.uint32(58).fork()).ldelim();
    }
    if (message.availableForSale === true) {
      writer.uint32(64).bool(message.availableForSale);
    }
    if (message.stockOnHand !== 0) {
      writer.uint32(72).int32(message.stockOnHand);
    }
    if (message.totalOrdersPerItem !== 0) {
      writer.uint32(80).int32(message.totalOrdersPerItem);
    }
    if (message.currency !== 0) {
      writer.uint32(88).int32(message.currency);
    }
    if (message.subcategoryId !== "") {
      writer.uint32(98).string(message.subcategoryId);
    }
    if (message.categoryId !== "") {
      writer.uint32(106).string(message.categoryId);
    }
    Object.entries(message.attributes).forEach(([key, value]) => {
      CatalogItem_AttributesEntry.encode({ key: key as any, value }, writer.uint32(114).fork()).ldelim();
    });
    for (const v of message.dependents) {
      CatalogItem.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    if (message.type !== 0) {
      writer.uint32(128).int32(message.type);
    }
    for (const v of message.tags) {
      Tag.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    if (message.descriptionShort !== "") {
      writer.uint32(146).string(message.descriptionShort);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CatalogItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCatalogItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalApiId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.catalogId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.priceInfo = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.image = Image.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.availableForSale = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.stockOnHand = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalOrdersPerItem = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.subcategoryId = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.categoryId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          const entry14 = CatalogItem_AttributesEntry.decode(reader, reader.uint32());
          if (entry14.value !== undefined) {
            message.attributes[entry14.key] = entry14.value;
          }
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.dependents.push(CatalogItem.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.tags.push(Tag.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.descriptionShort = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CatalogItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      externalApiId: isSet(object.externalApiId) ? String(object.externalApiId) : undefined,
      catalogId: isSet(object.catalogId) ? String(object.catalogId) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : undefined,
      priceInfo: isSet(object.priceInfo) ? Number(object.priceInfo) : 0,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      availableForSale: isSet(object.availableForSale) ? Boolean(object.availableForSale) : false,
      stockOnHand: isSet(object.stockOnHand) ? Number(object.stockOnHand) : 0,
      totalOrdersPerItem: isSet(object.totalOrdersPerItem) ? Number(object.totalOrdersPerItem) : 0,
      currency: isSet(object.currency) ? currencyFromJSON(object.currency) : 0,
      subcategoryId: isSet(object.subcategoryId) ? String(object.subcategoryId) : "",
      categoryId: isSet(object.categoryId) ? String(object.categoryId) : "",
      attributes: isObject(object.attributes)
        ? Object.entries(object.attributes).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      dependents: Array.isArray(object?.dependents) ? object.dependents.map((e: any) => CatalogItem.fromJSON(e)) : [],
      type: isSet(object.type) ? itemTypeFromJSON(object.type) : 0,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [],
      descriptionShort: isSet(object.descriptionShort) ? String(object.descriptionShort) : "",
    };
  },

  toJSON(message: CatalogItem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.externalApiId !== undefined) {
      obj.externalApiId = message.externalApiId;
    }
    if (message.catalogId !== "") {
      obj.catalogId = message.catalogId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== undefined) {
      obj.description = message.description;
    }
    if (message.priceInfo !== 0) {
      obj.priceInfo = Math.round(message.priceInfo);
    }
    if (message.image !== undefined) {
      obj.image = Image.toJSON(message.image);
    }
    if (message.availableForSale === true) {
      obj.availableForSale = message.availableForSale;
    }
    if (message.stockOnHand !== 0) {
      obj.stockOnHand = Math.round(message.stockOnHand);
    }
    if (message.totalOrdersPerItem !== 0) {
      obj.totalOrdersPerItem = Math.round(message.totalOrdersPerItem);
    }
    if (message.currency !== 0) {
      obj.currency = currencyToJSON(message.currency);
    }
    if (message.subcategoryId !== "") {
      obj.subcategoryId = message.subcategoryId;
    }
    if (message.categoryId !== "") {
      obj.categoryId = message.categoryId;
    }
    if (message.attributes) {
      const entries = Object.entries(message.attributes);
      if (entries.length > 0) {
        obj.attributes = {};
        entries.forEach(([k, v]) => {
          obj.attributes[k] = v;
        });
      }
    }
    if (message.dependents?.length) {
      obj.dependents = message.dependents.map((e) => CatalogItem.toJSON(e));
    }
    if (message.type !== 0) {
      obj.type = itemTypeToJSON(message.type);
    }
    if (message.tags?.length) {
      obj.tags = message.tags.map((e) => Tag.toJSON(e));
    }
    if (message.descriptionShort !== "") {
      obj.descriptionShort = message.descriptionShort;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CatalogItem>, I>>(base?: I): CatalogItem {
    return CatalogItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CatalogItem>, I>>(object: I): CatalogItem {
    const message = createBaseCatalogItem();
    message.id = object.id ?? "";
    message.externalApiId = object.externalApiId ?? undefined;
    message.catalogId = object.catalogId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? undefined;
    message.priceInfo = object.priceInfo ?? 0;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.availableForSale = object.availableForSale ?? false;
    message.stockOnHand = object.stockOnHand ?? 0;
    message.totalOrdersPerItem = object.totalOrdersPerItem ?? 0;
    message.currency = object.currency ?? 0;
    message.subcategoryId = object.subcategoryId ?? "";
    message.categoryId = object.categoryId ?? "";
    message.attributes = Object.entries(object.attributes ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.dependents = object.dependents?.map((e) => CatalogItem.fromPartial(e)) || [];
    message.type = object.type ?? 0;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.descriptionShort = object.descriptionShort ?? "";
    return message;
  },
};

function createBaseCatalogItem_AttributesEntry(): CatalogItem_AttributesEntry {
  return { key: "", value: "" };
}

export const CatalogItem_AttributesEntry = {
  encode(message: CatalogItem_AttributesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CatalogItem_AttributesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCatalogItem_AttributesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CatalogItem_AttributesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: CatalogItem_AttributesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CatalogItem_AttributesEntry>, I>>(base?: I): CatalogItem_AttributesEntry {
    return CatalogItem_AttributesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CatalogItem_AttributesEntry>, I>>(object: I): CatalogItem_AttributesEntry {
    const message = createBaseCatalogItem_AttributesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseBasicOrderItem(): BasicOrderItem {
  return { id: "", quantity: 0 };
}

export const BasicOrderItem = {
  encode(message: BasicOrderItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.quantity !== 0) {
      writer.uint32(16).int32(message.quantity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasicOrderItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasicOrderItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.quantity = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasicOrderItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      quantity: isSet(object.quantity) ? Number(object.quantity) : 0,
    };
  },

  toJSON(message: BasicOrderItem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BasicOrderItem>, I>>(base?: I): BasicOrderItem {
    return BasicOrderItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BasicOrderItem>, I>>(object: I): BasicOrderItem {
    const message = createBaseBasicOrderItem();
    message.id = object.id ?? "";
    message.quantity = object.quantity ?? 0;
    return message;
  },
};

function createBaseOrderItem(): OrderItem {
  return { catalogItem: undefined, quantity: 0, priceSubtotal: 0, description: "" };
}

export const OrderItem = {
  encode(message: OrderItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.catalogItem !== undefined) {
      CatalogItem.encode(message.catalogItem, writer.uint32(10).fork()).ldelim();
    }
    if (message.quantity !== 0) {
      writer.uint32(16).int32(message.quantity);
    }
    if (message.priceSubtotal !== 0) {
      writer.uint32(24).int32(message.priceSubtotal);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrderItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrderItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.catalogItem = CatalogItem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.quantity = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.priceSubtotal = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrderItem {
    return {
      catalogItem: isSet(object.catalogItem) ? CatalogItem.fromJSON(object.catalogItem) : undefined,
      quantity: isSet(object.quantity) ? Number(object.quantity) : 0,
      priceSubtotal: isSet(object.priceSubtotal) ? Number(object.priceSubtotal) : 0,
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: OrderItem): unknown {
    const obj: any = {};
    if (message.catalogItem !== undefined) {
      obj.catalogItem = CatalogItem.toJSON(message.catalogItem);
    }
    if (message.quantity !== 0) {
      obj.quantity = Math.round(message.quantity);
    }
    if (message.priceSubtotal !== 0) {
      obj.priceSubtotal = Math.round(message.priceSubtotal);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrderItem>, I>>(base?: I): OrderItem {
    return OrderItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OrderItem>, I>>(object: I): OrderItem {
    const message = createBaseOrderItem();
    message.catalogItem = (object.catalogItem !== undefined && object.catalogItem !== null)
      ? CatalogItem.fromPartial(object.catalogItem)
      : undefined;
    message.quantity = object.quantity ?? 0;
    message.priceSubtotal = object.priceSubtotal ?? 0;
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseBasketResponse(): BasketResponse {
  return {
    orderId: "",
    items: [],
    totalPrice: 0,
    status: 0,
    createdAt: undefined,
    updatedAt: undefined,
    paymentId: "",
    friendlyId: 0,
    deliveryAddress: "",
    kitchenNotes: "",
    collectionNotes: "",
    kioskLocationId: "",
    requireDeposit: false,
    deposit: 0,
    isDepositPaid: false,
    amountToPay: 0,
    notificationsConfirmed: false,
    customerName: undefined,
    phoneNumber: undefined,
    email: undefined,
    orderDuration: undefined,
  };
}

export const BasketResponse = {
  encode(message: BasketResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.orderId !== "") {
      writer.uint32(10).string(message.orderId);
    }
    for (const v of message.items) {
      OrderItem.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.totalPrice !== 0) {
      writer.uint32(24).int32(message.totalPrice);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.paymentId !== "") {
      writer.uint32(58).string(message.paymentId);
    }
    if (message.friendlyId !== 0) {
      writer.uint32(64).int32(message.friendlyId);
    }
    if (message.deliveryAddress !== "") {
      writer.uint32(74).string(message.deliveryAddress);
    }
    if (message.kitchenNotes !== "") {
      writer.uint32(82).string(message.kitchenNotes);
    }
    if (message.collectionNotes !== "") {
      writer.uint32(90).string(message.collectionNotes);
    }
    if (message.kioskLocationId !== "") {
      writer.uint32(98).string(message.kioskLocationId);
    }
    if (message.requireDeposit === true) {
      writer.uint32(104).bool(message.requireDeposit);
    }
    if (message.deposit !== 0) {
      writer.uint32(112).int32(message.deposit);
    }
    if (message.isDepositPaid === true) {
      writer.uint32(120).bool(message.isDepositPaid);
    }
    if (message.amountToPay !== 0) {
      writer.uint32(128).int32(message.amountToPay);
    }
    if (message.notificationsConfirmed === true) {
      writer.uint32(136).bool(message.notificationsConfirmed);
    }
    if (message.customerName !== undefined) {
      writer.uint32(146).string(message.customerName);
    }
    if (message.phoneNumber !== undefined) {
      writer.uint32(154).string(message.phoneNumber);
    }
    if (message.email !== undefined) {
      writer.uint32(162).string(message.email);
    }
    if (message.orderDuration !== undefined) {
      Duration.encode(message.orderDuration, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasketResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasketResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.items.push(OrderItem.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalPrice = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.paymentId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.friendlyId = reader.int32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deliveryAddress = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.kitchenNotes = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.collectionNotes = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.kioskLocationId = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.requireDeposit = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.deposit = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.isDepositPaid = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.amountToPay = reader.int32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.notificationsConfirmed = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.customerName = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.phoneNumber = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.email = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.orderDuration = Duration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasketResponse {
    return {
      orderId: isSet(object.orderId) ? String(object.orderId) : "",
      items: Array.isArray(object?.items) ? object.items.map((e: any) => OrderItem.fromJSON(e)) : [],
      totalPrice: isSet(object.totalPrice) ? Number(object.totalPrice) : 0,
      status: isSet(object.status) ? orderStatusFromJSON(object.status) : 0,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      paymentId: isSet(object.paymentId) ? String(object.paymentId) : "",
      friendlyId: isSet(object.friendlyId) ? Number(object.friendlyId) : 0,
      deliveryAddress: isSet(object.deliveryAddress) ? String(object.deliveryAddress) : "",
      kitchenNotes: isSet(object.kitchenNotes) ? String(object.kitchenNotes) : "",
      collectionNotes: isSet(object.collectionNotes) ? String(object.collectionNotes) : "",
      kioskLocationId: isSet(object.kioskLocationId) ? String(object.kioskLocationId) : "",
      requireDeposit: isSet(object.requireDeposit) ? Boolean(object.requireDeposit) : false,
      deposit: isSet(object.deposit) ? Number(object.deposit) : 0,
      isDepositPaid: isSet(object.isDepositPaid) ? Boolean(object.isDepositPaid) : false,
      amountToPay: isSet(object.amountToPay) ? Number(object.amountToPay) : 0,
      notificationsConfirmed: isSet(object.notificationsConfirmed) ? Boolean(object.notificationsConfirmed) : false,
      customerName: isSet(object.customerName) ? String(object.customerName) : undefined,
      phoneNumber: isSet(object.phoneNumber) ? String(object.phoneNumber) : undefined,
      email: isSet(object.email) ? String(object.email) : undefined,
      orderDuration: isSet(object.orderDuration) ? Duration.fromJSON(object.orderDuration) : undefined,
    };
  },

  toJSON(message: BasketResponse): unknown {
    const obj: any = {};
    if (message.orderId !== "") {
      obj.orderId = message.orderId;
    }
    if (message.items?.length) {
      obj.items = message.items.map((e) => OrderItem.toJSON(e));
    }
    if (message.totalPrice !== 0) {
      obj.totalPrice = Math.round(message.totalPrice);
    }
    if (message.status !== 0) {
      obj.status = orderStatusToJSON(message.status);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.paymentId !== "") {
      obj.paymentId = message.paymentId;
    }
    if (message.friendlyId !== 0) {
      obj.friendlyId = Math.round(message.friendlyId);
    }
    if (message.deliveryAddress !== "") {
      obj.deliveryAddress = message.deliveryAddress;
    }
    if (message.kitchenNotes !== "") {
      obj.kitchenNotes = message.kitchenNotes;
    }
    if (message.collectionNotes !== "") {
      obj.collectionNotes = message.collectionNotes;
    }
    if (message.kioskLocationId !== "") {
      obj.kioskLocationId = message.kioskLocationId;
    }
    if (message.requireDeposit === true) {
      obj.requireDeposit = message.requireDeposit;
    }
    if (message.deposit !== 0) {
      obj.deposit = Math.round(message.deposit);
    }
    if (message.isDepositPaid === true) {
      obj.isDepositPaid = message.isDepositPaid;
    }
    if (message.amountToPay !== 0) {
      obj.amountToPay = Math.round(message.amountToPay);
    }
    if (message.notificationsConfirmed === true) {
      obj.notificationsConfirmed = message.notificationsConfirmed;
    }
    if (message.customerName !== undefined) {
      obj.customerName = message.customerName;
    }
    if (message.phoneNumber !== undefined) {
      obj.phoneNumber = message.phoneNumber;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    if (message.orderDuration !== undefined) {
      obj.orderDuration = Duration.toJSON(message.orderDuration);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BasketResponse>, I>>(base?: I): BasketResponse {
    return BasketResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BasketResponse>, I>>(object: I): BasketResponse {
    const message = createBaseBasketResponse();
    message.orderId = object.orderId ?? "";
    message.items = object.items?.map((e) => OrderItem.fromPartial(e)) || [];
    message.totalPrice = object.totalPrice ?? 0;
    message.status = object.status ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.paymentId = object.paymentId ?? "";
    message.friendlyId = object.friendlyId ?? 0;
    message.deliveryAddress = object.deliveryAddress ?? "";
    message.kitchenNotes = object.kitchenNotes ?? "";
    message.collectionNotes = object.collectionNotes ?? "";
    message.kioskLocationId = object.kioskLocationId ?? "";
    message.requireDeposit = object.requireDeposit ?? false;
    message.deposit = object.deposit ?? 0;
    message.isDepositPaid = object.isDepositPaid ?? false;
    message.amountToPay = object.amountToPay ?? 0;
    message.notificationsConfirmed = object.notificationsConfirmed ?? false;
    message.customerName = object.customerName ?? undefined;
    message.phoneNumber = object.phoneNumber ?? undefined;
    message.email = object.email ?? undefined;
    message.orderDuration = (object.orderDuration !== undefined && object.orderDuration !== null)
      ? Duration.fromPartial(object.orderDuration)
      : undefined;
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
