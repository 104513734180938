/**
 * An interface for which represents installation progress.
 */
export interface InstallProgress {
  /** total number of resources to be loaded */
  total: number;
  /** the number of resources that are already loaded */
  current: number;
  /** progress, expressed as percent */
  percent: number;
}

/**
 * Observer handles service worker installation events.
 *
 * It acts as an intermediate between React code and the service worker.
 *
 * On service worker side:
 *
 * We need to tell the class what service worker we want to observe. That
 * service worker will be provided a channel which it can use to send
 * installation updates.
 *
 * On React side:
 *
 * We can use the onChange function to register a listener, which will receive
 * updates on installation progress. The components can also use the "ready"
 * method to know when service worker installation finished. Once this promise
 * expires, no more updates should be sent.
 *
 * ---
 *
 * This class also provides a static method "checkVersion" that can be used to
 * force the app and service worker to update to the latest version.
 */
export class Observer {
  /** The singleton instance */
  private static _instance: Observer;

  /** The local storage key used to denote a recently reloaded page */
  private static RELOADED_STORAGE_KEY = "reloaded";

  /** The message channel which will handle communication between the service worker components */
  private messageChannel: MessageChannel;

  /** We store the last state here so we can notify new listeners of the last known state. */
  private lastState?: InstallProgress;

  /** List of listeners (callbacks). */
  private listeners: { (progress: InstallProgress): void }[] = [];

  /** Set to true when we are already in progress of resetting */
  private resetting = false;

  constructor() {
    // Init message channel and set the handler.
    this.messageChannel = new MessageChannel();
    this.messageChannel.port1.onmessage = (event) => this.routeEvent(event);
  }

  /**
   * Obtain the singleton
   */
  public static get Instance(): Observer {
    return this._instance || (this._instance = new this());
  }

  /**
   * Takes an event from a service worker and routes it to the correct handler.
   *
   * @param event the event to process
   * @returns void
   */
  private routeEvent(event: MessageEvent): void {
    switch (event.data.type) {
      case "progress":
        this.handleProgress(event);
        return;
      case "auth":
        this.handleAuth(event);
        return;
      default:
        console.warn(
          "an unknown message received from the service worker",
          event
        );
    }
  }

  /**
   * Handles the install progress messages coming from service worker.
   *
   * It updates the "last known" installation state and then notifies all
   * listeners of the change.
   *
   * This method should be used to process messages from the message channel.
   *
   * @param event the message event
   */
  private handleProgress(event: MessageEvent): void {
    const current = parseInt(event.data.current || 0);
    const total = parseInt(event.data.total || 0);

    const percent = total === 0 ? 0 : current / total;

    this.lastState = {
      current,
      total,
      percent: percent > 1 ? 1 : percent,
    };

    for (const listener of this.listeners) {
      listener(this.lastState);
    }
  }

  /**
   * Handles auth messages coming from service worker.
   *
   * If service worker detects an expired token, it will instruct the app to
   * reset, prompting for authentication.
   *
   * @param event the message event
   */
  private handleAuth(event: MessageEvent): void {
    if (event.data?.reset) {
      this.clearAndReset();
    }
  }

  /**
   * Sets the callback which will be invoked on installation progress change.
   *
   * This function can be used by any component that needs to be aware of the
   * installation progress.
   *
   * @param callback the callback to invoke
   */
  public onChange(callback: (progress: InstallProgress) => void): void {
    if (this.lastState) {
      callback(this.lastState);
    }

    this.listeners.push(callback);
  }

  /**
   * Returns a promise which will resolve once service worker is ready (loaded &
   * activated).
   *
   * Once this promise is resolved, no more updates will be sent to the service
   * worker installation channel.
   *
   * @returns ready service worker promise
   */
  public ready(): Promise<ServiceWorkerRegistration> {
    return navigator.serviceWorker.ready;
  }

  /**
   * Sets the communication channel to the given service worker.
   *
   * The message channel will receive the messages.
   *
   * @param sw service worker to observe
   */
  public observe(sw: ServiceWorker): void {
    sw.postMessage({ type: "INIT_PORTS" }, [this.messageChannel.port2]);
  }

  /**
   * Checks the app version with backend.
   *
   * If version is mismatched, it clears the cache by calling the /clear
   * endpoint which has Clear-Site-Data headers set (clearing cache and storage,
   * including service workers). Afterwards, we reload the page.
   *
   * Should only be executed on initial load, BEFORE react is initialized.
   *
   * @returns void
   */
  public async checkVersion(): Promise<void> {
    // Don't run the version check on dev OR if current version is unknown.
    if (
      !process.env.REACT_APP_VERSION ||
      process.env.REACT_APP_VERSION === "dev"
    ) {
      return;
    }

    // Prevent infinite reloads in case cache clear fails
    if (window.localStorage.getItem(Observer.RELOADED_STORAGE_KEY)) {
      window.localStorage.removeItem(Observer.RELOADED_STORAGE_KEY);
      return;
    }

    try {
      // Obtain current version reported by backend
      const resp = await fetch(`/version?_=${Date.now()}`);

      // Check if version is different from current FE build
      if ((await resp.text()).trim() !== process.env.REACT_APP_VERSION.trim()) {
        await this.clearAndReset();
      }
    } catch (e) {
      console.error("cannot check deployment version", e);
    }
  }

  /**
   * Calls the clear-cache endpoint and reloads the page.
   *
   * @returns void
   */
  private async clearAndReset() {
    if (this.resetting) {
      return;
    }

    this.resetting = true;

    await fetch("/clear");

    window.localStorage.setItem(Observer.RELOADED_STORAGE_KEY, "1");
    window.location.reload();
  }
}

export default Observer.Instance;
