/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Duration } from "../../google/protobuf/duration";

export const protobufPackage = "digitalbutler.v1";

export enum PaymentTerminal {
  UNSPECIFIED_PAYMENT_TERMINAL = 0,
  STRIPE = 1,
  STRIPE_SIMULATED = 2,
  STRIPE_JS = 3,
  STRIPE_JS_SIMULATED = 4,
  STRIPE_BACKEND = 5,
  UNRECOGNIZED = -1,
}

export function paymentTerminalFromJSON(object: any): PaymentTerminal {
  switch (object) {
    case 0:
    case "UNSPECIFIED_PAYMENT_TERMINAL":
      return PaymentTerminal.UNSPECIFIED_PAYMENT_TERMINAL;
    case 1:
    case "STRIPE":
      return PaymentTerminal.STRIPE;
    case 2:
    case "STRIPE_SIMULATED":
      return PaymentTerminal.STRIPE_SIMULATED;
    case 3:
    case "STRIPE_JS":
      return PaymentTerminal.STRIPE_JS;
    case 4:
    case "STRIPE_JS_SIMULATED":
      return PaymentTerminal.STRIPE_JS_SIMULATED;
    case 5:
    case "STRIPE_BACKEND":
      return PaymentTerminal.STRIPE_BACKEND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PaymentTerminal.UNRECOGNIZED;
  }
}

export function paymentTerminalToJSON(object: PaymentTerminal): string {
  switch (object) {
    case PaymentTerminal.UNSPECIFIED_PAYMENT_TERMINAL:
      return "UNSPECIFIED_PAYMENT_TERMINAL";
    case PaymentTerminal.STRIPE:
      return "STRIPE";
    case PaymentTerminal.STRIPE_SIMULATED:
      return "STRIPE_SIMULATED";
    case PaymentTerminal.STRIPE_JS:
      return "STRIPE_JS";
    case PaymentTerminal.STRIPE_JS_SIMULATED:
      return "STRIPE_JS_SIMULATED";
    case PaymentTerminal.STRIPE_BACKEND:
      return "STRIPE_BACKEND";
    case PaymentTerminal.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface KioskLocation {
  id: string;
  name: string;
  description: string;
  theme: string;
  latitude: number;
  longitude: number;
  paymentTerminal: PaymentTerminal;
  stripeLocationId: string;
  orderCount: number;
  inactivityTimeout: number;
  printerConnection: string;
  shouldFetchDefaultItems: boolean;
  stripeTerminalId: string;
  livelloFridgeId: string;
  shouldFetchAiPrompt: boolean;
}

export interface GetLocationRequest {
  id: string;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface NearbyAttraction {
  id: string;
  name: string;
  duration: Duration | undefined;
  image: string;
  location: Coordinates | undefined;
}

export interface GetNearbyAttractionsRequest {
  kioskLocationId?: string | undefined;
  currentLocation?: Coordinates | undefined;
}

export interface GetNearbyAttractionsResponse {
  nearbyAttractions: NearbyAttraction[];
}

function createBaseKioskLocation(): KioskLocation {
  return {
    id: "",
    name: "",
    description: "",
    theme: "",
    latitude: 0,
    longitude: 0,
    paymentTerminal: 0,
    stripeLocationId: "",
    orderCount: 0,
    inactivityTimeout: 0,
    printerConnection: "",
    shouldFetchDefaultItems: false,
    stripeTerminalId: "",
    livelloFridgeId: "",
    shouldFetchAiPrompt: false,
  };
}

export const KioskLocation = {
  encode(message: KioskLocation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.theme !== "") {
      writer.uint32(34).string(message.theme);
    }
    if (message.latitude !== 0) {
      writer.uint32(45).float(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(53).float(message.longitude);
    }
    if (message.paymentTerminal !== 0) {
      writer.uint32(56).int32(message.paymentTerminal);
    }
    if (message.stripeLocationId !== "") {
      writer.uint32(66).string(message.stripeLocationId);
    }
    if (message.orderCount !== 0) {
      writer.uint32(72).int64(message.orderCount);
    }
    if (message.inactivityTimeout !== 0) {
      writer.uint32(80).int32(message.inactivityTimeout);
    }
    if (message.printerConnection !== "") {
      writer.uint32(90).string(message.printerConnection);
    }
    if (message.shouldFetchDefaultItems === true) {
      writer.uint32(96).bool(message.shouldFetchDefaultItems);
    }
    if (message.stripeTerminalId !== "") {
      writer.uint32(106).string(message.stripeTerminalId);
    }
    if (message.livelloFridgeId !== "") {
      writer.uint32(114).string(message.livelloFridgeId);
    }
    if (message.shouldFetchAiPrompt === true) {
      writer.uint32(120).bool(message.shouldFetchAiPrompt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KioskLocation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKioskLocation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.theme = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.latitude = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.longitude = reader.float();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.paymentTerminal = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.stripeLocationId = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.orderCount = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.inactivityTimeout = reader.int32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.printerConnection = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.shouldFetchDefaultItems = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.stripeTerminalId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.livelloFridgeId = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.shouldFetchAiPrompt = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KioskLocation {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      description: isSet(object.description) ? String(object.description) : "",
      theme: isSet(object.theme) ? String(object.theme) : "",
      latitude: isSet(object.latitude) ? Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? Number(object.longitude) : 0,
      paymentTerminal: isSet(object.paymentTerminal) ? paymentTerminalFromJSON(object.paymentTerminal) : 0,
      stripeLocationId: isSet(object.stripeLocationId) ? String(object.stripeLocationId) : "",
      orderCount: isSet(object.orderCount) ? Number(object.orderCount) : 0,
      inactivityTimeout: isSet(object.inactivityTimeout) ? Number(object.inactivityTimeout) : 0,
      printerConnection: isSet(object.printerConnection) ? String(object.printerConnection) : "",
      shouldFetchDefaultItems: isSet(object.shouldFetchDefaultItems) ? Boolean(object.shouldFetchDefaultItems) : false,
      stripeTerminalId: isSet(object.stripeTerminalId) ? String(object.stripeTerminalId) : "",
      livelloFridgeId: isSet(object.livelloFridgeId) ? String(object.livelloFridgeId) : "",
      shouldFetchAiPrompt: isSet(object.shouldFetchAiPrompt) ? Boolean(object.shouldFetchAiPrompt) : false,
    };
  },

  toJSON(message: KioskLocation): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.theme !== "") {
      obj.theme = message.theme;
    }
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    if (message.paymentTerminal !== 0) {
      obj.paymentTerminal = paymentTerminalToJSON(message.paymentTerminal);
    }
    if (message.stripeLocationId !== "") {
      obj.stripeLocationId = message.stripeLocationId;
    }
    if (message.orderCount !== 0) {
      obj.orderCount = Math.round(message.orderCount);
    }
    if (message.inactivityTimeout !== 0) {
      obj.inactivityTimeout = Math.round(message.inactivityTimeout);
    }
    if (message.printerConnection !== "") {
      obj.printerConnection = message.printerConnection;
    }
    if (message.shouldFetchDefaultItems === true) {
      obj.shouldFetchDefaultItems = message.shouldFetchDefaultItems;
    }
    if (message.stripeTerminalId !== "") {
      obj.stripeTerminalId = message.stripeTerminalId;
    }
    if (message.livelloFridgeId !== "") {
      obj.livelloFridgeId = message.livelloFridgeId;
    }
    if (message.shouldFetchAiPrompt === true) {
      obj.shouldFetchAiPrompt = message.shouldFetchAiPrompt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KioskLocation>, I>>(base?: I): KioskLocation {
    return KioskLocation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<KioskLocation>, I>>(object: I): KioskLocation {
    const message = createBaseKioskLocation();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.theme = object.theme ?? "";
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.paymentTerminal = object.paymentTerminal ?? 0;
    message.stripeLocationId = object.stripeLocationId ?? "";
    message.orderCount = object.orderCount ?? 0;
    message.inactivityTimeout = object.inactivityTimeout ?? 0;
    message.printerConnection = object.printerConnection ?? "";
    message.shouldFetchDefaultItems = object.shouldFetchDefaultItems ?? false;
    message.stripeTerminalId = object.stripeTerminalId ?? "";
    message.livelloFridgeId = object.livelloFridgeId ?? "";
    message.shouldFetchAiPrompt = object.shouldFetchAiPrompt ?? false;
    return message;
  },
};

function createBaseGetLocationRequest(): GetLocationRequest {
  return { id: "" };
}

export const GetLocationRequest = {
  encode(message: GetLocationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLocationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLocationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLocationRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: GetLocationRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLocationRequest>, I>>(base?: I): GetLocationRequest {
    return GetLocationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetLocationRequest>, I>>(object: I): GetLocationRequest {
    const message = createBaseGetLocationRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCoordinates(): Coordinates {
  return { latitude: 0, longitude: 0 };
}

export const Coordinates = {
  encode(message: Coordinates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Coordinates {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCoordinates();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.longitude = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Coordinates {
    return {
      latitude: isSet(object.latitude) ? Number(object.latitude) : 0,
      longitude: isSet(object.longitude) ? Number(object.longitude) : 0,
    };
  },

  toJSON(message: Coordinates): unknown {
    const obj: any = {};
    if (message.latitude !== 0) {
      obj.latitude = message.latitude;
    }
    if (message.longitude !== 0) {
      obj.longitude = message.longitude;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Coordinates>, I>>(base?: I): Coordinates {
    return Coordinates.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Coordinates>, I>>(object: I): Coordinates {
    const message = createBaseCoordinates();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    return message;
  },
};

function createBaseNearbyAttraction(): NearbyAttraction {
  return { id: "", name: "", duration: undefined, image: "", location: undefined };
}

export const NearbyAttraction = {
  encode(message: NearbyAttraction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.duration !== undefined) {
      Duration.encode(message.duration, writer.uint32(26).fork()).ldelim();
    }
    if (message.image !== "") {
      writer.uint32(34).string(message.image);
    }
    if (message.location !== undefined) {
      Coordinates.encode(message.location, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NearbyAttraction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNearbyAttraction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.duration = Duration.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.image = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.location = Coordinates.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NearbyAttraction {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      location: isSet(object.location) ? Coordinates.fromJSON(object.location) : undefined,
    };
  },

  toJSON(message: NearbyAttraction): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.duration !== undefined) {
      obj.duration = Duration.toJSON(message.duration);
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    if (message.location !== undefined) {
      obj.location = Coordinates.toJSON(message.location);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NearbyAttraction>, I>>(base?: I): NearbyAttraction {
    return NearbyAttraction.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NearbyAttraction>, I>>(object: I): NearbyAttraction {
    const message = createBaseNearbyAttraction();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.duration = (object.duration !== undefined && object.duration !== null)
      ? Duration.fromPartial(object.duration)
      : undefined;
    message.image = object.image ?? "";
    message.location = (object.location !== undefined && object.location !== null)
      ? Coordinates.fromPartial(object.location)
      : undefined;
    return message;
  },
};

function createBaseGetNearbyAttractionsRequest(): GetNearbyAttractionsRequest {
  return { kioskLocationId: undefined, currentLocation: undefined };
}

export const GetNearbyAttractionsRequest = {
  encode(message: GetNearbyAttractionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.kioskLocationId !== undefined) {
      writer.uint32(10).string(message.kioskLocationId);
    }
    if (message.currentLocation !== undefined) {
      Coordinates.encode(message.currentLocation, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNearbyAttractionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNearbyAttractionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.kioskLocationId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currentLocation = Coordinates.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNearbyAttractionsRequest {
    return {
      kioskLocationId: isSet(object.kioskLocationId) ? String(object.kioskLocationId) : undefined,
      currentLocation: isSet(object.currentLocation) ? Coordinates.fromJSON(object.currentLocation) : undefined,
    };
  },

  toJSON(message: GetNearbyAttractionsRequest): unknown {
    const obj: any = {};
    if (message.kioskLocationId !== undefined) {
      obj.kioskLocationId = message.kioskLocationId;
    }
    if (message.currentLocation !== undefined) {
      obj.currentLocation = Coordinates.toJSON(message.currentLocation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNearbyAttractionsRequest>, I>>(base?: I): GetNearbyAttractionsRequest {
    return GetNearbyAttractionsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetNearbyAttractionsRequest>, I>>(object: I): GetNearbyAttractionsRequest {
    const message = createBaseGetNearbyAttractionsRequest();
    message.kioskLocationId = object.kioskLocationId ?? undefined;
    message.currentLocation = (object.currentLocation !== undefined && object.currentLocation !== null)
      ? Coordinates.fromPartial(object.currentLocation)
      : undefined;
    return message;
  },
};

function createBaseGetNearbyAttractionsResponse(): GetNearbyAttractionsResponse {
  return { nearbyAttractions: [] };
}

export const GetNearbyAttractionsResponse = {
  encode(message: GetNearbyAttractionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nearbyAttractions) {
      NearbyAttraction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNearbyAttractionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNearbyAttractionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nearbyAttractions.push(NearbyAttraction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNearbyAttractionsResponse {
    return {
      nearbyAttractions: Array.isArray(object?.nearbyAttractions)
        ? object.nearbyAttractions.map((e: any) => NearbyAttraction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetNearbyAttractionsResponse): unknown {
    const obj: any = {};
    if (message.nearbyAttractions?.length) {
      obj.nearbyAttractions = message.nearbyAttractions.map((e) => NearbyAttraction.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNearbyAttractionsResponse>, I>>(base?: I): GetNearbyAttractionsResponse {
    return GetNearbyAttractionsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetNearbyAttractionsResponse>, I>>(object: I): GetNearbyAttractionsResponse {
    const message = createBaseGetNearbyAttractionsResponse();
    message.nearbyAttractions = object.nearbyAttractions?.map((e) => NearbyAttraction.fromPartial(e)) || [];
    return message;
  },
};

export interface Location {
  GetLocation(
    request: DeepPartial<GetLocationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<KioskLocation>;
  GetNearbyAttractions(
    request: DeepPartial<GetNearbyAttractionsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetNearbyAttractionsResponse>;
}

export class LocationClientImpl implements Location {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetLocation = this.GetLocation.bind(this);
    this.GetNearbyAttractions = this.GetNearbyAttractions.bind(this);
  }

  GetLocation(
    request: DeepPartial<GetLocationRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<KioskLocation> {
    return this.rpc.unary(LocationGetLocationDesc, GetLocationRequest.fromPartial(request), metadata, abortSignal);
  }

  GetNearbyAttractions(
    request: DeepPartial<GetNearbyAttractionsRequest>,
    metadata?: grpc.Metadata,
    abortSignal?: AbortSignal,
  ): Promise<GetNearbyAttractionsResponse> {
    return this.rpc.unary(
      LocationGetNearbyAttractionsDesc,
      GetNearbyAttractionsRequest.fromPartial(request),
      metadata,
      abortSignal,
    );
  }
}

export const LocationDesc = { serviceName: "digitalbutler.v1.Location" };

export const LocationGetLocationDesc: UnaryMethodDefinitionish = {
  methodName: "GetLocation",
  service: LocationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLocationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = KioskLocation.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const LocationGetNearbyAttractionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNearbyAttractions",
  service: LocationDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNearbyAttractionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNearbyAttractionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
    abortSignal?: AbortSignal,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      const client = grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });

      if (abortSignal) {
        abortSignal.addEventListener("abort", () => {
          client.close();
          reject(abortSignal.reason);
        });
      }
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
